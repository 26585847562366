import "./Dashboard.css";
import React, { Component } from "react";
import dashboard from "../../Assets/dashboard.png";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";

// Local env configuration
require('dotenv').config()
const host_url = process.env.REACT_APP_API_URL

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            estimations: [],
            offers: [],
            demandes: [],
            messages: [],
            questions: [],
            answers: [],
            question: "",
            answer: "",
            err: "",
            title: "",
            desc: "",
            mission: "",
            mission1: "",
            mission2: "",
            mission3: "",
            mission4: "",
            missions: [],
            competence: "",
            competence1: "",
            competence2: "",
            competence3: "",
            competence4: "",
            competences: [],
            qualite: "",
            qualite1: "",
            qualite2: "",
            qualite3: "",
            qualite4: "",
            qualites: []
        }
        this.changeTitle = this.changeTitle.bind(this)
        this.changeDesc = this.changeDesc.bind(this)
        this.changeMission = this.changeMission.bind(this)
        this.changeMission1 = this.changeMission1.bind(this)
        this.changeMission2 = this.changeMission2.bind(this)
        this.changeMission3 = this.changeMission3.bind(this)
        this.changeMission4 = this.changeMission4.bind(this)
        this.changeCompetence = this.changeCompetence.bind(this)
        this.changeCompetence1 = this.changeCompetence1.bind(this)
        this.changeCompetence2 = this.changeCompetence2.bind(this)
        this.changeCompetence3 = this.changeCompetence3.bind(this)
        this.changeCompetence4 = this.changeCompetence4.bind(this)
        this.changeQualite = this.changeQualite.bind(this)
        this.changeQualite1 = this.changeQualite1.bind(this)
        this.changeQualite2 = this.changeQualite2.bind(this)
        this.changeQualite3 = this.changeQualite3.bind(this)
        this.changeQualite4 = this.changeQualite4.bind(this)
        this.addOffer = this.addOffer.bind(this)
        this.changeQuestion = this.changeQuestion.bind(this)
        this.changeAnswer = this.changeAnswer.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.deleteDemande = this.deleteDemande.bind(this)
        this.deleteOffer = this.deleteOffer.bind(this)
        this.deleteEstimation = this.deleteEstimation.bind(this)
        this.deleteMessage = this.deleteMessage.bind(this)
        this.deleteQuestion = this.deleteQuestion.bind(this)
        this.deleteAnswer = this.deleteAnswer.bind(this)
        this.logOut = this.logOut.bind(this)
    }
    logOut(){
        localStorage.removeItem("accessToken");
        window.location.reload();
    }
    

    deleteMessage(id) {
        axios.delete(`${host_url}/api/message/${id}`)
            .then(response => {
                const newMessages = this.state.messages.filter(message => id !== message.id)
                this.setState({
                    messages: newMessages
                })
                window.location.reload()
            })
    }

    deleteDemande(id) {
        axios.delete(`${host_url}/api/demande/${id}`)
            .then(response => {
                const newDemandes = this.state.demandes.filter(demande => id !== demande.id)
                this.setState({
                    demandes: newDemandes
                })
                window.location.reload()
            })
    }
    deleteOffer(id) {
        axios.delete(`${host_url}/api/offer/${id}`)
            .then(() => {
                const newOffers = this.state.offers.filter(offer => id !== offer.id)
                this.setState({
                    offers: newOffers
                })
                window.location.reload()
            })
    }
    deleteEstimation(id) {
        axios.delete(`${host_url}/api/estimation/${id}`)
            .then(() => {
                const newestimations = this.state.estimations.filter(estimation => id !== estimation.id)
                this.setState({
                    estimations: newestimations
                })
                window.location.reload()
            })
    }

    deleteQuestion(id) {
        axios.delete(`${host_url}/api/question/${id}`)
            .then(() => {
                const newquestions = this.state.questions.filter(question => id !== question.id)
                this.setState({
                    questions: newquestions
                })
                window.location.reload()
            })
    }

    deleteAnswer(id) {
        axios.delete(`${host_url}/api/answer/${id}`)
            .then(() => {
                const newanswers = this.state.answers.filter(answer => id !== answer.id)
                this.setState({
                    answers: newanswers
                })
                window.location.reload()
            })
    }

    componentDidMount() {

        axios.get(`${host_url}/api/estimations`)
            .then(response => {
                this.setState({
                    estimations: response.data
                })
            })

        axios.get(`${host_url}/api/offers`)
            .then(response => {
                this.setState({
                    offers: response.data
                })
            })

        axios.get(`${host_url}/api/demandes`)
            .then(response => {
                this.setState({
                    demandes: response.data
                })
            })
        axios.get(`${host_url}/api/messages`)
            .then(response => {
                this.setState({
                    messages: response.data
                })
            })
        axios.get(`${host_url}/api/questions`)
            .then(response => {
                this.setState({
                    questions: response.data
                })
            })
        axios.get(`${host_url}/api/answers`)
            .then(response => {
                this.setState({
                    answers: response.data
                })
            })
    }

    changeTitle(e) {
        this.setState({
            title: e.target.value
        })
    }
    changeDesc(e) {
        this.setState({
            desc: e.target.value
        })
    }
    changeMission(e) {
        this.setState({
            mission: e.target.value
        })

    }
    changeMission1(e) {
        this.setState({
            mission1: e.target.value
        })

    }
    changeMission2(e) {
        this.setState({
            mission2: e.target.value
        })

    }
    changeMission3(e) {
        this.setState({
            mission3: e.target.value
        })

    }
    changeMission4(e) {
        this.setState({
            mission4: e.target.value
        })

    }
    changeCompetence(e) {
        this.setState({
            competence: e.target.value
        })
    }
    changeCompetence1(e) {
        this.setState({
            competence1: e.target.value
        })
    }
    changeCompetence2(e) {
        this.setState({
            competence2: e.target.value
        })
    }
    changeCompetence3(e) {
        this.setState({
            competence3: e.target.value
        })
    }
    changeCompetence4(e) {
        this.setState({
            competence4: e.target.value
        })
    }
    changeQualite(e) {
        this.setState({
            qualite: e.target.value
        })
    }
    changeQualite1(e) {
        this.setState({
            qualite1: e.target.value
        })
    }
    changeQualite2(e) {
        this.setState({
            qualite2: e.target.value
        })
    }
    changeQualite3(e) {
        this.setState({
            qualite3: e.target.value
        })
    }
    changeQualite4(e) {
        this.setState({
            qualite4: e.target.value
        })
    }

    addOffer(e) {
        e.preventDefault()
        
        this.state.missions.push(this.state.mission)
        this.state.missions.push(this.state.mission1)
        this.state.missions.push(this.state.mission2)
        this.state.missions.push(this.state.mission3)
        this.state.missions.push(this.state.mission4)

        this.state.competences.push(this.state.competence)
        this.state.competences.push(this.state.competence1)
        this.state.competences.push(this.state.competence2)
        this.state.competences.push(this.state.competence3)
        this.state.competences.push(this.state.competence4)

        this.state.qualites.push(this.state.qualite)
        this.state.qualites.push(this.state.qualite1)
        this.state.qualites.push(this.state.qualite2)
        this.state.qualites.push(this.state.qualite3)
        this.state.qualites.push(this.state.qualite4)

        sleep(100).then(() => {
            var offer = {
                title: this.state.title,
                description: this.state.desc,
                missions: this.state.missions,
                competences: this.state.competences,
                qualites: this.state.qualites
            }
            console.log(offer)
            axios.post(`${host_url}/api/offer`, offer)
                .then(res => {
                    this.setState({
                        err: res.data
                    })
                }
                )
                window.location.reload()
            
        });
        
    }

    changeQuestion(e) {
        this.setState({
            question: e.target.value
        })
    }

    changeAnswer(e) {
        this.setState({
            answer: e.target.value
        })
    }


    onSubmit(e) {
        e.preventDefault()

        const QR = {
            question: this.state.question,
            answer: this.state.answer
        }
        axios.post(`${host_url}/api/answer`, QR)
            .then(res => {
                this.setState({
                    err: res.data
                })
            })
            window.location.reload()
    }

    render() {
        return (
            <main>
                <div className="main-container">
                    <div className="main-title">
                        <img src={dashboard} alt="Dashboard" />
                        <div className="main-greeting">
                            <h1>Bonjour l'administrateur de Solairius</h1>
                            <p>Bienvenue sur votre tableau de bord</p>
                        </div>
                    </div>
                    <div className="logout"><button onClick={this.logOut} className="btn">Log Out</button></div>
                    {/* Here is the cards that shows statistics of all time*/}
                    <div className="main-cards">
                        <div className="card">
                            <i className="fa fa-usd fa-2x text-lightblue" />
                            <div className="card-inner">
                                <p className="text-primary">Les demandes de devis</p>
                                <span>{this.state.estimations.length}</span>
                            </div>
                        </div>
                        <div className="card">
                            <i className="fa fa-file-text-o fa-2x text-red" />
                            <div className="card-inner">
                                <p className="text-primary">Les demandes d'emploi</p>
                                <span>{this.state.demandes.length}</span>
                            </div>
                        </div>
                        <div className="card">
                            <i className="fa fa-briefcase fa-2x text-yellow" />
                            <div className="card-inner">
                                <p className="text-primary">Les offres d'emploi</p>
                                <span>{this.state.offers.length} </span>
                            </div>
                        </div>
                        <div className="card">
                            <i className="fa fa-briefcase fa-2x text-yellow" />
                            <div className="card-inner">
                                <p className="text-primary">Les messages</p>
                                <span>{this.state.messages.length} </span>
                            </div>
                        </div>
                        <div className="card">
                            <i className="fa fa-briefcase fa-2x text-yellow" />
                            <div className="card-inner">
                                <p className="text-primary">Les Questions</p>
                                <span>{this.state.questions.length} </span>
                            </div>
                        </div>
                    </div>
                    <div className="charts">
                        <div className="charts-left">
                            <div className="charts-left-title">
                                <div>
                                    <h1>Les Demandes d'emploi</h1>
                                </div>
                                <i className="fa fa-file-text-o" />
                            </div>
                            <div className="card-list container">
                                {this.state.demandes.map((demande) => (
                                    <article className="container card pt-2 card2">
                                        <Popup
                                            modal
                                            contentStyle={{
                                                background: "rgba(255,255,255,0",
                                                width: "50%",
                                                border: "none"
                                            }}
                                            trigger={
                                                <i className="fa fa-times" aria-hidden="true"></i>
                                            }>{close => (
                                                <div className="popupDiv">
                                                    <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                    <div className="info__card">
                                                        <h4>Voulez vous supprimer cette demande ?</h4>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <button className="btn" onClick={() => this.deleteDemande(demande.id)} >Oui</button>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <button className="btn" onClick={close} >Non</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Popup>
                                        <div className="card-body">
                                            <p className="date">{demande.dateString} </p>
                                            <h5>{demande.title}</h5>
                                            <h5>{demande.lastName} {demande.firstName} </h5>
                                            <p className="body-content">PhoneNumber : {demande.phoneNumber}<br />E-mail: {demande.email} </p>
                                            <button className="button button-primary">
                                                <a href={demande.cv} ><i className="fa fa-chevron-right"></i> Download CV</a>
                                        </button>
                                        </div>
                                    </article>
                                ))}

                            </div>
                        </div>
                        <div className="charts-right">
                            <div className="charts-right-title">
                                <div>
                                    <h1>Les demandes de devis</h1>
                                </div>
                                <i className="fa fa-usd" />
                            </div>
                            <div className="charts-right-cards">
                                {this.state.estimations.map((estimation) => (
                                    <Popup
                                        modal
                                        contentStyle={{
                                            background: "rgba(255,255,255,0",
                                            width: "50%",
                                            border: "none"
                                        }}
                                        trigger={
                                            <div className="card-right">
                                                <Popup
                                                    modal
                                                    contentStyle={{
                                                        background: "rgba(255,255,255,0",
                                                        width: "50%",
                                                        border: "none"
                                                    }}
                                                    trigger={
                                                        <i className="fa fa-times" aria-hidden="true"></i>
                                                    }>{close => (
                                                        <div className="popupDiv">
                                                            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                            <div className="info__card">
                                                                <h4>Voulez vous supprimer cette demande ?</h4>
                                                                <div className="row">
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <button type="submit" className="btn" onClick={() => this.deleteEstimation(estimation.id)}>Oui</button>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                            <button type="submit" className="btn" onClick={close}>Non</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                                <h1>{estimation.firstName} {estimation.lastName}</h1>
                                                <p>{estimation.estimation}</p>
                                            </div>
                                        }>{close => (
                                            <div className="popupDiv1">
                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                <h4>{estimation.dateString}</h4><br />
                                                <div className="info__card">
                                                    <form>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Firstname :</label><br />
                                                                    <input type="text" name="firstName" value={estimation.firstName} className="form-control" disabled />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Lastname: </label><br />
                                                                    <input type="text" name="lastName" value={estimation.lastName} className="form-control" disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>E-mail:</label>
                                                                    <input type="email" name="email" value={estimation.email} className="form-control" disabled />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Phone:</label><br />
                                                                    <input type="text" name="phoneNumber" value={estimation.phoneNumber} className="form-control" disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                                <div className="form-group">
                                                                    <label>Commentaire:</label>
                                                                    <textarea type="text" name="comment" value={estimation.commentaire} className="form-control" disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Secteur:</label>
                                                                    <input type="email" name="sector" value={estimation.sector} className="form-control" disabled />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Région:</label><br />
                                                                    <input type="text" name="region" value={estimation.region} className="form-control" disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Eléctricité:</label>
                                                                    <input type="email" name="electricite" value={estimation.electricite} className="form-control" disabled />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Génératrice:</label><br />
                                                                    <input type="text" name="generatrice" value={estimation.generatrice} className="form-control" disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Factures:</label>
                                                                    <ul>
                                                                        {estimation.factures.map((facture) => (
                                                                            <li>{facture} </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Gaz:</label>
                                                                    <input type="email" name="gaz" value={estimation.gaz} className="form-control" disabled />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Type:</label><br />
                                                                    <input type="text" name="type" value={estimation.type} className="form-control" disabled />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Equipments:</label>
                                                                    <ul>
                                                                        {estimation.equipments.map((equipment) => (
                                                                            <li>{equipment} </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Autres Equipments:</label>
                                                                    <ul>
                                                                        <li>{estimation.autres} </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="form-group">
                                                                    <label>Pourcentage de couverture:</label>
                                                                    <ul>
                                                                        <li>{estimation.pourcentage} </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>

                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="offersCards">
                        <div className="offers-title">
                            <div>
                                <h1>Les Questions</h1>
                            </div>
                            <Popup
                                modal
                                contentStyle={{
                                    background: "rgba(255,255,255,0",
                                    width: "50%",
                                    border: "none"
                                }}
                                trigger={
                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                }>{close => (
                                    <div className="popupDiv">
                                        <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                        <div className="info__card">
                                            <form method="POST" onSubmit={this.onSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Question:</label>
                                                            <input type="text" name="question" className="form-control" onChange={this.changeQuestion} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Réponse:</label>
                                                            <textarea type="text" name="answer" className="form-control" onChange={this.changeAnswer} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn" >Publier la Q/R</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <p>{this.state.err}</p>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </div>
                        <div className="main-cards">
                            {this.state.questions.map((question, i) => (
                                <Popup
                                    modal
                                    contentStyle={{
                                        background: "rgba(255,255,255,0",
                                        width: "50%",
                                        border: "none"
                                    }}
                                    trigger={
                                        <div className="card" key={i}>
                                            <Popup
                                                modal
                                                contentStyle={{
                                                    background: "rgba(255,255,255,0",
                                                    width: "50%",
                                                    border: "none"
                                                }}
                                                trigger={
                                                    <i className="fa fa-times fa-2x text-lightblue" />
                                                }>{close => (
                                                    <div className="popupDiv">
                                                        <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                        <div className="info__card">
                                                            <h4>Voulez vous supprimer cette question?</h4>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="form-group">
                                                                        <button type="submit" className="btn" onClick={() => this.deleteQuestion(question.id)}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="form-group">
                                                                        <button type="submit" className="btn">Non</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>
                                            <div className="card-inner">
                                                <p className="text-primary">{question.question}</p>
                                            </div>
                                            <p className="text">{question.answer}</p>
                                        </div>
                                    }>{close => (
                                        <div className="popupDiv">
                                            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                            <div className="info__card">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>name:</label>
                                                                <input type="text" name="name" className="form-control" value={question.name} disabled />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <label>Télé:</label>
                                                                <input type="text" name="phoneNumber" className="form-control" value={question.phoneNumber} disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>E-mail:</label>
                                                                <input type="email" name="email" className="form-control" value={question.email} disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Question:</label>
                                                                <textarea type="text" name="question" className="form-control" value={question.question} disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <p>{this.state.err}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            ))}
                        </div>
                    </div>
                    <div className="offersCards">
                        <div className="offers-title">
                            <div>
                                <h1>Les FAQs</h1>
                            </div>
                        </div>
                        <div className="main-cards">
                            {this.state.answers.map((answer) => (
                                <Popup
                                    modal
                                    contentStyle={{
                                        background: "rgba(255,255,255,0",
                                        width: "50%",
                                        border: "none"
                                    }}
                                    trigger={
                                        <div className="card">
                                            <Popup
                                                modal
                                                contentStyle={{
                                                    background: "rgba(255,255,255,0",
                                                    width: "50%",
                                                    border: "none"
                                                }}
                                                trigger={
                                                    <i className="fa fa-times fa-2x text-lightblue" />
                                                }>{close => (
                                                    <div className="popupDiv">
                                                        <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                        <div className="info__card">
                                                            <h4>Voulez vous supprimer cette answer?</h4>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="form-group">
                                                                        <button type="submit" className="btn" onClick={() => this.deleteAnswer(answer.id)}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="form-group">
                                                                        <button type="submit" className="btn">Non</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>
                                            <div className="card-inner">
                                                <p className="text-primary">{answer.question}</p>
                                            </div>
                                        </div>
                                    }>{close => (
                                        <div className="popupDiv">
                                            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                            <div className="info__card">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Question:</label>
                                                                <textarea type="text" name="question" className="form-control" value={answer.question} disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Answer:</label>
                                                                <textarea type="text" name="answer" className="form-control" value={answer.answer} disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                <p>{this.state.err}</p>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="charts">
                    <div className="charts-right">
                        <div className="charts-right-title">
                            <div>
                                <h1>Les Offres d'emplois</h1>
                            </div>
                            <Popup
                                modal
                                contentStyle={{
                                    background: "rgba(255,255,255,0",
                                    width: "50%",
                                    border: "none"
                                }}
                                trigger={
                                    <i className="fa fa-plus" />
                                }>{close => (
                                    <div className="popupDiv1">
                                        <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                        <div className="info__card">
                                            <form method="POST" onSubmit={this.addOffer}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>titre :</label><br />
                                                            <input type="text" name="title" className="form-control" onChange={this.changeTitle} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Déscription:</label>
                                                            <textarea type="text" name="desc" className="form-control" onChange={this.changeDesc} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Mission Principales:</label>
                                                            <input type="text" name="mission" className="form-control" onChange={this.changeMission} />
                                                            <input type="text" name="mission" className="form-control" onChange={this.changeMission1} />
                                                            <input type="text" name="mission" className="form-control" onChange={this.changeMission2} />
                                                            <input type="text" name="mission" className="form-control" onChange={this.changeMission3} />
                                                            <input type="text" name="mission" className="form-control" onChange={this.changeMission4} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Compétences requises:</label>
                                                            <input type="text" name="competence" className="form-control" onChange={this.changeCompetence} />
                                                            <input type="text" name="competence" className="form-control" onChange={this.changeCompetence1} />
                                                            <input type="text" name="competence" className="form-control" onChange={this.changeCompetence2} />
                                                            <input type="text" name="competence" className="form-control" onChange={this.changeCompetence3} />
                                                            <input type="text" name="competence" className="form-control" onChange={this.changeCompetence4} />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label>Qualité Personnelles:</label>
                                                            <input type="text" name="qualite" className="form-control" onChange={this.changeQualite} />
                                                            <input type="text" name="qualite" className="form-control" onChange={this.changeQualite1} />
                                                            <input type="text" name="qualite" className="form-control" onChange={this.changeQualite2} />
                                                            <input type="text" name="qualite" className="form-control" onChange={this.changeQualite3} />
                                                            <input type="text" name="qualite" className="form-control" onChange={this.changeQualite4} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn">Publier l'offre</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <p>{this.state.err}</p>
                                        </div>
                                    </div>
                                )}
                            </Popup>


                        </div>
                        <div className="charts-right-cards">
                            {this.state.offers.map((offer, i) => (
                                <Popup
                                    modal
                                    contentStyle={{
                                        background: "rgba(255,255,255,0",
                                        width: "50%",
                                        border: "none"
                                    }}
                                    trigger={
                                        <div className="card-right" key={i}>
                                            <Popup
                                                modal
                                                contentStyle={{
                                                    background: "rgba(255,255,255,0",
                                                    width: "50%",
                                                    border: "none"
                                                }}
                                                trigger={
                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                }>{close => (
                                                    <div className="popupDiv">
                                                        <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                        <div className="info__card">
                                                            <h4>Voulez vous supprimer cette offre ?</h4>
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <div className="form-group">
                                                                        <button type="submit" className="btn" onClick={() => this.deleteOffer(offer.id)}>Oui</button>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6">
                                                                    <div className="form-group">
                                                                        <button type="submit" className="btn" onClick={close}>Non</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Popup>
                                            <h3>{offer.title}</h3>
                                        </div>
                                    }>{close => (
                                        <div className="popupDiv1">
                                            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                            <h4>{offer.dateString}</h4><br />
                                            <div className="info__card">
                                                <form>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Titre:</label><br />
                                                                <input type="text" name="title" value={offer.title} className="form-control" disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Description</label>
                                                                <textarea type="text" name="description" value={offer.description} className="form-control" disabled />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Missions Principales</label>
                                                                <ul>
                                                                    {offer.missions.map((mission, j) => (
                                                                        <li key={j}>{mission} </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Compétences requises:</label>
                                                                <ul>
                                                                    {offer.competences.map((competence, k) => (
                                                                        <li key={k}>{competence} </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="form-group">
                                                                <label>Qualités personneles:</label>
                                                                <ul>
                                                                    {offer.qualites.map((qualite, l) => (
                                                                        <li key={l}>{qualite} </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    )}
                                </Popup>
                            ))}
                        </div>
                    </div>
                    <div className="charts-left">
                        <div className="charts-left-title">
                            <div>
                                <h1>Les Messages</h1>
                            </div>
                            <i className="fa fa-file-text-o" />
                        </div>
                        <div className="card-list container">
                            {this.state.messages.map((message) => (
                                <article className="container card pt-2 card2">
                                    <Popup
                                        modal
                                        contentStyle={{
                                            background: "rgba(255,255,255,0",
                                            width: "50%",
                                            border: "none"
                                        }}
                                        trigger={
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        }>{close => (
                                            <div className="popupDiv">
                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                <div className="info__card">
                                                    <h4>Voulez vous supprimer cette message ?</h4>
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <button className="btn" onClick={() => this.deleteMessage(message.id)} >Oui</button>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-group">
                                                                <button className="btn" onClick={close} >Non</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Popup>
                                    <div className="card-body">
                                        <p className="date">{message.dateString} </p>
                                        <h5>{message.name}</h5>
                                        <p className="body-content">PhoneNumber : {message.phoneNumber}<br />E-mail: {message.email} </p>
                                        <p> <i className="fa fa-chevron-right"></i>{message.message} </p>
                                    </div>
                                </article>
                            ))}
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default Dashboard;