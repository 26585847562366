import "./OurMindset.css"
import React, { Component } from "react";
import support from '../../Assets/support.png';
import engin from '../../Assets/engin.png'
import money from '../../Assets/money.png'
import certif from '../../Assets/certif.png'
import content from '../../Contents/HomeContent';



class OurMindset extends Component {
    constructor(props){
        super(props);
        this.Language = this.props.language;
        
      }
     

    render() {
        return (
            <div id="OurMindset">
                <h1 className="title text-center" >{content.ourmindtitl[this.Language]}</h1>
                <div className="ourmindset-section">
                    
                    <div className="container ">
                        
                        <div className="card-wrapper text-center">
                             
                            <div className="mindset-box  text-center ">
                                <img className="mindsetimage" alt="" src={engin} />
                                
                                <h5>{content.ourmindtitle1[this.Language]}</h5>
                                <p className="text-center pp ">{content.ourmind1[this.Language]} </p>
                            </div>
                            <div className="mindset-box  text-center ">
                                <img className="mindsetimage" alt="" src={money} />
                                <h5>{content.ourmindtitle2[this.Language]}</h5>
                                <p className="text-center pp"> {content.ourmind2[this.Language]} </p>
                            </div>
                            <div className="mindset-box  text-center ">
                                <img className="mindsetimage" alt="" src={certif} />    
                                <h5>{content.ourmindtitle3[this.Language]}</h5>
                                <p className="text-center pp">{content.ourmind3[this.Language]}</p>
                            </div>
                            <div className="mindset-box   text-center ">
                                <img className="mindsetimage" alt="" src={support} />
                                <h5>{content.ourmindtitle4[this.Language]}</h5>
                                <p className="text-center pp">{content.ourmind4[this.Language]} </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default OurMindset;