import "./FAQ.css";
import React from 'react';
import axios from "axios";

// Local env configuration
require('dotenv').config()
const host_url = process.env.REACT_APP_API_URL


class AccordionApp extends React.Component {
    constructor() {
        super();
    
        this.state = {
          QuestionsA: [],
        }
      }
    
      componentDidMount() {
        window.scrollTo(0, 0);
        axios.get(`${host_url}/api/answers`)
          .then(response => {
            this.setState({
              QuestionsA: response.data
            })
          })
      }
  render() {
      
      return (
          <div className="container ">
              <h1>.</h1>
           
              <Header />
              <Accordion hiddenTexts={this.state.QuestionsA} />
          
          </div>
      );
  }
}

class Header extends React.Component {
  render() {
      return (

         <div> <h3>FAQ</h3></div> 
      );
  }
}

class Accordion extends React.Component {
  render() {
      return (
          
          <div className="accordion container mt-5 " >
            
            
              {this.props.hiddenTexts.map((hiddenText, i) => <AccordionItem key={i} hiddenText={hiddenText} />)}
             
          </div>
      );
  }
}

class AccordionItem extends React.Component {
  
  constructor(props) {
      super(props);
      this.handleToggleVisibility = this.handleToggleVisibility.bind(this);
      this.state = {
          visibility: false
      }
  }
  componentDidMount() {
    window.scrollTo(0, 0);
}
  handleToggleVisibility() {
      this.setState((prevState) => {
          return {
              
              visibility: !prevState.visibility,
          }
      })
  }
  render() {
      const activeStatus = this.state.visibility ? 'active' : ''

      return (
          <div>
              <button className="accordion__button" onClick={this.handleToggleVisibility}>{this.props.hiddenText.question}<span className={this.state.visibility? 'fa fa-minus': 'fa fa-plus'}></span></button>
              <p className={`accordion__content ${activeStatus}`}>
              {
                  this.props.hiddenText.answer
              }
              </p>
              
          </div>
      );
  }
}

  



  
export default AccordionApp;