import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Banner from './Components/Banner/Banner';
import NavBar2 from './Components/NavBar/NavBar2';
import Cookies from 'universal-cookie';
import AboutUs from './Components/AboutUs/AboutUs';
import OurMindset from './Components/OurMindset/OurMindset';
import Footer from './Components/Footer/Footer';
import Footer2 from './Components/Footer2/Footer2';
import AccordionApp from './Components//FAQ/FAQ';
import Form from './Components/Form/Form';
import Estimate from './Components/EstimateCosts/Estimate';
import ContactUs from './Components/ContactUs/ContactUs';
import Slidshow from './Components/Slidshow/Slidshow'
import Career from './Components/Career/Career';
import Carousel from './Components/Carousel/Carousel';
import Login from './Components/Login/login';
import Contact from './Components/ContactUs/contact';
const cookies = new Cookies();


class App extends Component {
  constructor() {
    super();
    this.state = {
      language: cookies.get('language') == null ? "Fr" : cookies.get('language'),
      token: localStorage.getItem("accessToken")
    };
  }


  getLanguage(lang) {
    cookies.set('language', lang, { path: '/' });
    this.setState({
      language: cookies.get('language')
    })

  }
  
 
  render() {
    return (
      <Router>
        <div className="app">
          <Switch>
            <Route exact path="/">
              <NavBar2 langue={this.getLanguage.bind(this)} language={this.state.language}/>
              <Banner language={this.state.language}/>
              <AboutUs language={this.state.language}/>
              <OurMindset language={this.state.language} />
              <Carousel language={this.state.language}/>
              <Footer2 language={this.state.language} />
              <Footer language={this.state.language} />
            </Route>
            <Route path="/aboutUs">
              <NavBar2 langue={this.getLanguage.bind(this)} language={this.state.language}/>
              <Slidshow />
              <ContactUs language={this.state.language} />
              <Carousel language={this.state.language}/>
            </Route>  
            <Route path="/contactus">
              <NavBar2 langue={this.getLanguage.bind(this)} language={this.state.language}/>
              <Contact  language={this.state.language} />
              <Footer language={this.state.language} />
            </Route> 
            <Route path="/faq">
              <NavBar2 langue={this.getLanguage.bind(this)} language={this.state.language}/>
              <AccordionApp language={this.state.language}/>
              <Form language={this.state.language} />
              <Footer language={this.state.language} />
            </Route>
            <Route path="/estimate">
              <NavBar2 langue={this.getLanguage.bind(this)} language={this.state.language}/>
              <Estimate language={this.state.language}/>
            </Route>
            <Route path="/career">
              <NavBar2 langue={this.getLanguage.bind(this)} language={this.state.language}/>
              <Career language={this.state.language} />
              <Footer language={this.state.language} />
            </Route>
            <Route path="/dashboard">
                <Login />
            </Route>
          </Switch>
        </div>
      
      </Router>
    );
  }
}
export default App;