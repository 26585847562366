import "./NavBar.css";
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import content from '../../Contents/NavBarContent';
import logo from "../../Assets/logo.png"


class NavBar2 extends Component {
    constructor(props){
        super(props);
        this.Language = this.props.language;
        
      }
    
    getArabe(){
        this.props.langue("Ar");
        window.location.reload();
    }
    getFrancais(){
        this.props.langue("Fr");
        window.location.reload();
    }
    getEnglish(){
        this.props.langue("En");
        window.location.reload();
    }

    render() {
        return (
            <header>
                <nav className="navbar navbar-expand-lg  ">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">
                            <img className="img-fluid" src={logo} alt="Solairus" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive"
                            aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarResponsive">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active" >
                                    <Link className="nav-link mb-0 h1" data-toggle="collapse" data-target=".navbar-collapse.show" to="/">{content.home[this.Language]}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mb-0 h1" data-toggle="collapse" data-target=".navbar-collapse.show" to="/aboutUs">{content.aboutUs[this.Language]}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mb-0 h1" data-toggle="collapse" data-target=".navbar-collapse.show" to="/career">{content.career[this.Language]}</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link mb-0 h1" data-toggle="collapse" data-target=".navbar-collapse.show" to="/faq">{content.faq[this.Language]}</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <span className="nav-link dropdown-toggle" to="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        {this.Language}
                                    </span>
                                    <div className="sub-content container  d-flex justify-content-center " type="button">
                                        <div className="sub-drop row"  >
                                            
                                            <ul>
                                                <li><Link className="sub-drop-item " onClick={this.getEnglish.bind(this)} to="/">En</Link></li>
                                                <li><Link className="sub-drop-item" onClick={this.getFrancais.bind(this)} to="/">Fr</Link></li>
                                                <li><Link className="sub-drop-item" onClick={this.getArabe.bind(this)} to="/">Ar</Link></li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link className="myButton2" data-toggle="collapse" data-target=".navbar-collapse.show" to="/estimate">{content.estimateCosts[this.Language]}</Link>

                                  
                                    
                                            

                                </li>

                                
                                    
                                
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        );
    }
}

export default NavBar2;