module.exports = {
    aboutustitle:{
        En:"Who are we ?",
        Fr:"Qui somme nous ?",
        Ar:"من نحن ؟"
    },
    aboutuscontent1:{
        Fr:"SOLAIRius est une entreprise Africaine localisé au Maroc et Créée dans l’objectif d’offrir à ses clients :",
        En:"SOLAIRius is an African company located in Morocco and Created with the objective of offering its customers:",
        Ar:"سولاريوص هي شركة أفريقية تقع في المغرب وتم إنشاؤها بهدف تقديم:"
    },
    aboutuscontent2:{
        Fr:"►  Un service d’ingénierie, d’étude, d’analyse de faisabilité et de gestion de projet. ",
        En:"►  An engineering, study, feasibility analysis and project management service. ",
        Ar:"►  خدمة هندسية ودراسة وتحليل الجدوى وإدارة المشاريع. "
    },
    aboutuscontent3:{
        Fr:"►  Une solution énergétique solaire, thermique et éolienne de type clés en main incluant l’étude, le choix de produit, l’accompagnement avant et après installation, ceci garni d’une optimisation de l’utilisation énergétique afin de maximiser votre retour sur investissement. ",
        En:"►  A turnkey solar, thermal and wind energy solution including study, choice of product, support before and after installation, along with optimization of energy use in order to maximize your return on investment. ",
        Ar:"►  حل متكامل للطاقة الشمسية والحرارية وطاقة الرياح بما في ذلك الدراسة واختيار المنتج والدعم قبل التثبيت وبعده ، إلى جانب تحسين استخدام الطاقة من أجل تعظيم عائد الاستثمار." 
    },
    toutDemande:{
        En:"For all requests and assistance",
        Fr:"Pour toutes demandes et assistance",
        Ar:"لجميع الطلبات والمساعدات"
    },
    localisation:{
        En:"Localization",
        Fr:"Localisation",
        Ar:"الموقع"
    },
    address:{
        En:"Write to us at",
        Fr:"Écrivez-nous à",
        Ar:"اكتب إلينا على"
    },
    appeler:{
        En:"Call us at",
        Fr:"Appelez-nous au",
        Ar:"اتصل بنا على"
    },
    contactUs:{
        En:"Contact Us",
        Fr:"Nous contacter",
        Ar:"اتصل بنا"
    },
    name:{
        En:"Full Name",
        Fr:"Nom Complet",
        Ar:"الاسم بالكامل"
    },
    email:{
        En:"Email",
        Fr:"E-mail",
        Ar:"البريد الإلكتروني"
    },
    phone:{
        En:"Phone Number",
        Fr:"Numéro de Téléphone",
        Ar:"رقم الهاتف"
    },
    message:{
        En:"Your Message",
        Fr:"Votre Message",
        Ar:"رسالتك"
    },
    send:{
        En:"Send",
        Fr:"Envoyer",
        Ar:"إرسال"
    }, 

}