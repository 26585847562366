module.exports = {
    ourmindtitle1:{
        En:"Engineering at customer service",
        Fr:"L’ingénierie au sevice du client",
        Ar:"الهندسة في خدمة العملاء"
    },
    ourmindtitle2:{
        En:"ROE Maximization",
        Fr:"Maximisation du ROE",
        Ar:"تعظيم عائد الجودة"
    },
    ourmindtitle3:{
        En:"Use of Certified method and product",
        Fr:"Utilisation de méthode et de produit Certifié",
        Ar:"استخدام الطريقة والمنتج المعتمد"
    },
    ourmindtitle4:{
        En:"Continuous support",
        Fr:"Accompagnement et Support continue",
        Ar:"الدعم والمساندة المستمرة"
    },
    ourmind1:{
        En:"Find the solution that best suits our customers's current and future needs.",
        Fr:"Trouver la solution la plus juste au besoin existants et futurs de nos clients.",
        Ar:"إيجاد أفضل الحلول لاحتياجات عملائنا الحالية والمستقبلية."
    },
    ourmind2:{
        En:"Reduce the operating costs of our customers in order to maximize their return on investment.",
        Fr:"Réduire les coûts d'opération de nos clients afin de maximiser leur retour sur investissement.",
        Ar:"تقليل تكاليف التشغيل لعملائنا من أجل تعظيم عائد الاستثمار."
    },
    ourmind3:{
        En:"Use proven analysis and calculation methods and certified products.",
        Fr:"Utiliser des méthodes d'analyse et de calcul prouvé et des produits certifies.",
        Ar:"استخدم طرق التحليل والحساب التي أثبتت جدواها والمنتجات المعتمدة."
    },
    ourmind4:{
        En:"Always listening to our customers and ready to intervene if necessary.",
        Fr:"Toujours à l’écoute de nos clients et prêt à intervenir sur place si necessaire.",
        Ar:"نستمع دائمًا لعملائنا ومستعدون للتدخل إذا لزم الأمر."
    },
    headercontent1:{
        En:"Engineering, study and installation service for photovoltaic energy solutions throughout Africa.",
        Fr:"Service d’ingénierie, d’étude et d’installation de solution énergétique photovoltaïque partout en Afrique.",
        Ar:"خدمة الهندسة والدراسة والتركيب لحلول الطاقة الكهروضوئية في جميع أنحاء إفريقيا"
    },
    headercontent:{
        En:"SOLAIRIUS offers you a unique and quality product adjusted to your own financial and operational situation. Our strength lies in our quality of service in American standards, in our choice of product and in our support and after-sales service.",
        Fr:"SOLAIRIUS vous offre un produit unique et de qualité ajusté à votre propre situation financière et opérationnelle. Notre force figure dans notre qualité de prestation de service dans les normes américaine, dans notre choix de produit et dans notre accompagnement et service après-ventes.",
        Ar:"تقدم لك سولاريوص منتجًا فريدًا وعالي الجودة يتم تعديله وفقًا لوضعك المالي والتشغيلي. تكمن قوتنا في جودة خدماتنا وفقًا للمعايير الأمريكية ، وفي اختيارنا للمنتج وفي دعمنا وخدمة ما بعد البيع."
    },
    headertitle:{
        En:"From The Sun To You",
        Fr:"Du soleil vers vous",
        Ar:"من الشمس اليك"
    },
    estimateCosts:{
        En:"Estimate the Costs",
        Fr:"Estimer les Coûts",
        Ar:"تقدير التكاليف"
    },
    haveQuestion:{
        En:"I Have a Question",
        Fr:"J'ai une Question",
        Ar:"عندي سؤال"
    },
    footertitle:{
        En:"THANK YOU FOR VISITING US",
        Fr:"MERCI DE VOTRE VISITE",
        Ar:"شكرا لزيارتكم لنا"
    },
    footer1:{
        En:"Do not hesitate to contact us or ask if you have any questions.",
        Fr:"N'hésitez pas à nous contacter ou à nous demander si vous avez des questions.",
        Ar:"لا تتردد في الاتصال بنا أو سؤالنا إذا كان لديك أي أسئلة."
    },
    footer2:{
        En:"We are always here for you.",
        Fr:"Nous sommes toujours là pour votre service.",
        Ar:"نحن دائما هنا لخدمتك."
    },
    ourmindtitl:{
        En:"OUR MINDSET",
        Fr:"NOTRE PRINCIPE",
        Ar:"مبدأنا"
    },
    projects:{
        En:"PROJECTS",
        Fr:"PROJETS",
        Ar:"مشاريع "
    },
    titleCarousel:{
        En:"Project 106.8 KWp - Beni mellal region",
        Fr:"Projet 106.8 KWc - region de Beni mellal",
        Ar:"مشروع 106.8 كيلوواط - منطقة بني ملال "
    }, 
    pCarousel:{
        En:"Realization of an extendable project connected to the 106.8 Kwp network to supply all the equipment of the 147 hectare farm using: 3 submerged pump: 125hp at 160m and a flow rate of 180m3 / h, 2 * 40hp at 50m and 90m3 / h, 2 surface pumps (basin irrigation): 75 and 50hp for a total flow of 400m3 / h. The installation is expandable up to 132 Kwp.",
        Fr:"Réalisation d’un projet extensible connecté au réseau de 106,8 Kwc pour alimenter l’ensemble des équipements de la ferme de 147 hectares utilisant: 3 pompe submergée : 125cv à 160m et un débit de 180m3/h, 2*40cv à 50m et 90m3/h, 2 pompes de surface (irrigation du bassin) : 75 et 50cv pour un débit total de 400m3/h. L’installation est extensible jusqu’à 132 Kwc.",
        Ar:"تنفيذ مشروع قابل للتمديد متصل بشبكة 106.8 Kwp لتزويد جميع معدات مزرعة 147 هكتار باستخدام: 3 مضخات مغمورة: 125hp عند 160m ومعدل تدفق 180m3 / h ، 2 * 40hp عند 50m و 90m3 / h ، 2 المضخات السطحية (الري بالحوض): 75 و 50 حصان لتدفق إجمالي 400 م 3 / ساعة. التركيب قابل للتوسيع حتى 132 كيلو واط. "
    },
}