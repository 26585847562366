import React from "react";
import "./Form.css";
import axios from "axios";
import content from '../../Contents/FAQContent';


// Local env configuration
require('dotenv').config()
const host_url = process.env.REACT_APP_API_URL


class Form extends React.Component {
  constructor(props) {
    super(props);
    this.Language = this.props.language;
    this.state = {
      err: "",
      name: "",
      email: "",
      phoneNumber: "",
      question: ""
    }
    this.changeName = this.changeName.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePhoneNumber = this.changePhoneNumber.bind(this);
    this.changeQuestion = this.changeQuestion.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  changeName(e){
    this.setState({
        name: e.target.value
    })
}
changeEmail(e){
    this.setState({
        email: e.target.value
    })
}
changePhoneNumber(e){
    this.setState({
        phoneNumber: e.target.value
    })
}
changeQuestion(e){
    this.setState({
        question: e.target.value
    })
}

onSubmit(e){
    e.preventDefault()

   var question={
        name: this.state.name,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        question: this.state.question,
    }
    axios.post(`${host_url}/api/question`, question)
        .then(res =>
            this.setState({
                err: res.data
            })
            
        )

}

  render() {
    return (
      <div className="row">
        <div className="container mt-5 mb-5" id="feedback-form1">
          <h2 className="header">{content.faqst[this.Language]}</h2>
          <div>
            <form method="POST" onSubmit={this.onSubmit}>
            <input type="text" name="name" placeholder={content.name[this.Language]} onChange={this.changeName} ></input>
              <input type="email" name="email" placeholder={content.email[this.Language]} onChange={this.changeEmail}></input>
              <input type="text" name="phoneNumber" placeholder={content.phone[this.Language]} onChange={this.changePhoneNumber}></input>
              <div> {content.question[this.Language]} :</div>
              <input type="text" name="Question"  className="textera" onChange={this.changeQuestion}></input>
              <button type="submit">{content.send[this.Language]}</button>
            </form>
            <p>{this.state.err} </p>
          </div>
        </div>
      </div>
    );
  }
  };

  export default Form;