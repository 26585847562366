module.exports = {
    home:{
        En:"HOME",
        Fr:"ACCUEIL",
        Ar:"الصفحة الرئيسية"
    },
    aboutUs:{
        En:"ABOUT US",
        Fr:"À PROPOS",
        Ar:"معلومات عنا"
    },
    faq:{
        En:"FAQ",
        Fr:"FAQ",
        Ar:"الأسئلة الشائعة"
    },
    contactus:{
        En:"CONTACT US",
        Fr:"CONTACTEZ-NOUS",
        Ar:"تواصل معنا"
    },
    estimateCosts:{
        En:"Estimate the Costs",
        Fr:"Estimer les Coûts",
        Ar:"تقدير التكاليف"
    },
    career:{
        En:"CAREERS",
        Fr:"CARRIÈRES",
        Ar:"وظائف"
    }
}