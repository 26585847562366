import './Career.css';
import React from "react";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import axios from "axios";
import content from '../../Contents/NavBarContent';
import firebase from "firebase";

// Local env configuration
require('dotenv').config()
const host_url = process.env.REACT_APP_API_URL

var files = [];
var CVUrl;
var rnd = Math.random();
//  configuration de firebase

var firebaseConfig = {
  apiKey: "AIzaSyAkokp4fdEjq3HC2kBR61wZtnmSrlsbCuc",
  authDomain: "solairius-49114.firebaseapp.com",
  databaseURL: "https://solairius-49114-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "solairius-49114",
  storageBucket: "gs://solairius-49114.appspot.com/",
  messagingSenderId: "433635372047",
  appId: "1:433635372047:web:3f309a1b24a9e88d31477a"
};

firebase.initializeApp(firebaseConfig);


// Start App

class Career extends React.Component {
  constructor(props) {
    super(props);
    this.Language = this.props.language;
    this.state = {
      offers: [],
      response: "",
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    axios.get(`${host_url}/api/offers`)
      .then(response => {
        this.setState({
          offers: response.data
        })
      })
  }


  render() {
    return <div className="">
      <div className="container career  ">
        <h2 className="cardheader  ">{content.career[this.Language]}</h2>
        <div className="app-card-list  " id="app-card-list">
          {this.state.offers.map((offer) => (
            <Card title={offer.title} text={offer.description} date={offer.dateString} missions={offer.missions} competences={offer.competences} qualites={offer.qualites} />
          ))
          }
        </div>
      </div>
    </div>
  }
}


class CardBody extends React.Component {
  render() {
    return (
      <div className="card-body">
        <p className="date">{this.props.date} </p>

        <h5>{this.props.title}</h5>

        <p className="body-content">{this.props.text}</p>
      </div>
    )
  }
}


class Card extends React.Component {
  constructor(props) {
    super(props);
    this.Language = this.props.language;
    this.forceUpdate();
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      file: new FormData()
    }
    this.changefirstName = this.changefirstName.bind(this)
    this.changelastName = this.changelastName.bind(this)
    this.changeemail = this.changeemail.bind(this)
    this.changephoneNumber = this.changephoneNumber.bind(this)
    this.uploadFile = this.uploadFile.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  changefirstName(event) {
    this.setState({
      firstName: event.target.value
    })
  }

  changelastName(event) {
    this.setState({
      lastName: event.target.value
    })
  }

  changeemail(event) {
    this.setState({
      email: event.target.value
    })
  }

  changephoneNumber(event) {
    this.setState({
      phoneNumber: event.target.value
    })
  }

  uploadFile(event) {
    files = event.target.files;
  }

  async onSubmit(event) {

    event.preventDefault()

    await firebase.storage().ref('/CVs/' + this.state.lastName + rnd + '.pdf').put(files[0])
      .then(async () => {
       await firebase.storage().ref('/CVs/' + this.state.lastName + rnd + '.pdf').getDownloadURL().then((url) => {
          CVUrl = url;
        });
      });



    const demande = {
      title: this.props.title,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      cv: CVUrl
    }
    axios.post(`${host_url}/api/demande`, demande)
      .then((res) => {
        this.setState({
          response: res.data
        })
      }


      )

  }
  render() {
    return (
      <Popup
        modal
        contentStyle={{
          background: "rgba(255,255,255,0",
          width: "80%",
          border: "none"
        }}
        trigger={
          <article className=" card pt-2">
            <CardBody title={this.props.title} text={this.props.text} date={this.props.date} missions={this.props.missions} competences={this.props.competences} qualites={this.props.qualites} />
          </article>
        }>{close => (
          <div className="popupDiv1">
            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
            <div className="card-body1">
              <p className="date">{this.props.date} </p>

              <h5>{this.props.title}</h5>

              <p className="body-content">{this.props.text}</p>
              <p className="titles">Missions Principales</p>
              <ul>
                {this.props.missions.map((mission, j) => (
                  mission !== "" ? (
                    <li key={j}>{mission} </li>
                  ) : (
                    <p></p>
                  )
                ))}
              </ul>
              <p className="titles">Compétences requises</p>
              <ul>
                {this.props.competences.map((competence, k) => (
                  competence !== "" ? (
                    <li key={k}>{competence} </li>
                  ) : (
                    <p></p>
                  )
                ))}
              </ul>
              <p className="titles">Missions Principales</p>
              <ul>
                {this.props.qualites.map((qualite, l) => (
                  qualite !== "" ? (
                    <li key={l}>{qualite} </li>
                  ) : (
                    <p></p>
                  )
                ))}
              </ul>
              <div className="info__card">
                <form method="POST" onSubmit={this.onSubmit}>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Firstname :</label><br />
                        <input type="text" name="Name" className="form-control" value={this.state.firstName} onChange={this.changefirstName} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Lastname: </label><br />
                        <input type="text" name="lastName" className="form-control" value={this.state.lastName} onChange={this.changelastName} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>E-mail:</label>
                        <input type="email" name="email" className="form-control" value={this.state.email} onChange={this.changeemail} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <label>Phone:</label><br />
                        <input type="text" name="phoneNumber" className="form-control" value={this.state.phoneNumber} onChange={this.changephoneNumber} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <input id="select" type="file" name="thumbnail" className="cv-input" onChange={this.uploadFile} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <button type="submit" className="btn"> Postuler</button>
                      </div>
                    </div>
                  </div>
                  <p>{this.state.response} </p>
                </form>
              </div>
            </div>
          </div>
        )}
      </Popup>

    )
  }
}



export default Career;
