module.exports = {
    sector:{
        En:"What is your sector?",
        Fr:"Quel est votre secteur ?",
        Ar:"ما هو قطاعك ؟"
    },
    residential:{
        En:"Residential",
        Fr:"Résidentiel",
        Ar:"سكني"
    },
    inWhichRegion:{
        En:"In which region are you located ?",
        Fr:"Dans quelle région vous vous situez ?",
        Ar:"في أي منطقة تقيم ؟"
    },
    haveElec:{
        En:"Do you have electricity?",
        Fr:"Avez-vous de l'electricité ?",
        Ar:"هل عندك كهرباء ؟"
    },
    yes:{
        En:"Yes",
        Fr:"Oui",
        Ar:"نعم"
    },
    no:{
        En:"No",
        Fr:"Non",
        Ar:"لا"
    },
    elecbill:{
        En:"What is the average electricity bill per month in DHs ?",
        Fr:"Quelle est la facture moyenne d'électricité par mois en DHs ?",
        Ar:"ما هو متوسط ​​فاتورة الكهرباء في الشهر بالدرهم ؟"
    },
    usingGenerator:{
        En:"Are you using a generator ?",
        Fr:"Utilisez-vous une génératrice ?",
        Ar:"هل تستخدم مولد ؟"
    },
    typeResidence:{
        En:"What is the type of residence ?",
        Fr:"Quelle est le type de résidence ?",
        Ar:"ما هو نوع الاقامة ؟"
    },
    apartment:{
        En:"Apartment",
        Fr:"Appartement",
        Ar:"شقة"
    },
    house:{
        En:"House",
        Fr:"Maison",
        Ar:"منزل"
    },
    Villa:{
        En:"Villa",
        Fr:"Villa",
        Ar:"فيلا"
    },
    equipmentInPlace:{
        En:"Select the electrical equipment in place",
        Fr:"Sélectionnez les équipements électrique en place",
        Ar:"حدد المعدات الكهربائية في المكان"
    },
    clima:{
        En:"Air conditioner / Heating",
        Fr:"Climatiseur/Chauffage",
        Ar:"مكيف / تدفئة"
    },
    four:{
        En:"Electric oven",
        Fr:"Four électrique",
        Ar:"فرن كهربائي"
    },
    plaque:{
        En:"Electric hob",
        Fr:"Plaque de cuisson électrique",
        Ar:"فرن كهربائي"
    },
    chauffe:{
        En:"Electric water heater",
        Fr:"Chauffe Eau électrique",
        Ar:"سخانات المياه الكهربائية"
    },
    pc:{
        En:"PC and laptop",
        Fr:"PC et laptop",
        Ar:"الكمبيوتر الشخصي والكمبيوتر المحمول"
    },
    fridge:{
        En:"Fridge",
        Fr:"Réfrigérateur",
        Ar:"ثلاجة"
    },
    freezer:{
        En:"Freezer",
        Fr:"Congélateur",
        Ar:"الفريزر"
    },
    tv:{
        En:"Television",
        Fr:"Télévision",
        Ar:"التلفاز"
    },
    bulb:{
        En:"Bulb",
        Fr:"Ampoule",
        Ar:"مصباح"
    },
    machine:{
        En:"Washing machine",
        Fr:"Machine à laver",
        Ar:"غسالة"
    },
    jacuzzi:{
        En:"Hammam / Electric Jacuzzi",
        Fr:"Hammam/Jacuzzi électrique",
        Ar:"حمام / جاكوزي كهربائي"
    },
    pompe:{
        En:"Pump (well, swimming pool, etc.)",
        Fr:"Pompe (puit, piscine, etc)",
        Ar:"مضخة (بئر ، حمام سباحة ، إلخ.)"
    },
    agricole:{
        En:"Agricultural",
        Fr:"Agricole",
        Ar:"زراعي"
    },
    gaz:{
        En:"Do you use gas (bota)?",
        Fr:"Utilisez-vous du gaz (bota) ?",
        Ar:"هل تستخدم الغاز (bota)؟"
    },
    billWinter:{
        En:"What is the average electricity bill in Winter (October to April) ?",
        Fr:"Quelle est la facture moyenne d'électricité en Hiver (Octobre à Avril) ?",
        Ar:"ما هو متوسط ​​فاتورة الكهرباء في الشتاء (من أكتوبر إلى أبريل) ؟"
    },
    billSummer:{
        En:"What is the average electricity bill in Summer (May to September) ?",
        Fr:"Quelle est la facture moyenne d'électricité en Été (Mai à Septembre) ?",
        Ar:"ما هو متوسط ​​فاتورة الكهرباء في الصيف (مايو - سبتمبر) ؟"
    },
    pompeSub:{
        En:"Submerged pump",
        Fr:"Pompe submergé",
        Ar:"مضخة مغمورة"
    },
    pompePress:{
        En:"pressure pump",
        Fr:"pompe de pression",
        Ar:"مضخة الضغط"
    },
    brouilleur:{
        En:"Jammer",
        Fr:"Brouilleur",
        Ar:"جهاز التشويش"
    },
    autrePompe:{
        En:"Other pumps",
        Fr:"Autre pompes",
        Ar:"مضخات أخرى"
    },
    autreEquip:{
        En:"Other equipment",
        Fr:"Autre équipements",
        Ar:"معدات أخرى"
    },
    Industrial:{
        En:"Industrial",
        Fr:"Industriel",
        Ar:"صناعي"
    },
    typeUsine:{
        En:"What is the type of factory ?",
        Fr:"Quelle est le type d'usine ?",
        Ar:"ما هو نوع المصنع ؟"
    },
    metal:{
        En:"Metal production",
        Fr:"Production métallique",
        Ar:"إنتاج المعادن"
    },
    agro:{
        En:"Food production",
        Fr:"Production Agro alimentaire",
        Ar:"إنتاج الغذاء"
    },
    logistique:{
        En:"Logistics Center",
        Fr:"Centre de Logistique",
        Ar:"مركز اللوجستيات"
    },
    conditionnement:{
        En:"Conditioning center",
        Fr:"Centre de conditionnement",
        Ar:"مركز التكييف"
    },
    frigo:{
        En:"Refrigeration center",
        Fr:"Centre frigorifique",
        Ar:"مركز التبريد"
    },
    autre:{
        En:"Other",
        Fr:"Autre",
        Ar:"أخرى"
    },
    moteur:{
        En:"Engine",
        Fr:"Moteur",
        Ar:"محرك"
    },
    compresseur:{
        En:"Compressor",
        Fr:"Compresseur",
        Ar:"ضاغط"
    },
    convoyeur:{
        En:"rinsing / waxing machine",
        Fr:"machine rinçage/cirage",
        Ar:"آلة الشطف / الصبح"
    },
    chariot:{
        En:"Forklift (Clark)",
        Fr:"Chariot élévateur (Clark)",
        Ar:"رافعة شوكية (كلارك)"
    },
    estimation:{
        En:"An estimate according to the data provided is :",
        Fr:"Une estimation selon les donnée fournie est :",
        Ar:":تقدير حسب البيانات المقدمة"
    },
    etude:{
        En:"A detailed study is necessary for an accurate assessment of your consumption.",
        Fr:"Une étude détaillé est nécessaire pour une évaluation exacte de votre consommation.",
        Ar:". الدراسة التفصيلية ضرورية لإجراء تقييم دقيق لاستهلاكك"
    },
    reset:{
        En:"Reset Questions",
        Fr:"Réinitialiser les questions",
        Ar:"إعادة الأسئلة"
    },
    selectRegion:{
        En:"Select a region",
        Fr:"Sélectionner une région",
        Ar:"حدد منطقة"
    },
    submit:{
        En:"Submit answer",
        Fr:"Soumettre la réponse",
        Ar:"سلم الاجابة"
    },
    petit:{
        En:"Small",
        Fr:"Petit",
        Ar:"صغير"
    },
    moyenne:{
        En:"Average",
        Fr:"Moyenne",
        Ar:"متوسط"
    },
    grand:{
        En:"Big",
        Fr:"Grand",
        Ar:"كبير"
    },
    cathodic:{
        En:"Cathodic",
        Fr:"Cathodique",
        Ar:"كاثودي"
    },
    less32:{
        En:"Less than 32 inch",
        Fr:"Moin de 32 pouce",
        Ar:"أقل من 32 بوصة"
    },
    more32:{
        En:"32 to 50 inch",
        Fr:"32 à 50 pouce",
        Ar:"32-50 بوصة "
    },
    firstName:{
        En:"First Name ",
        Fr:"Prénom",
        Ar:"الاسم الأول"
    },
    lastName:{
        En:"Last Name",
        Fr:"Nom de famille",
        Ar:"الكنية"
    },
    email:{
        En:"Email",
        Fr:"E-mail",
        Ar:"بريد إلكتروني"
    },
    phoneNumber:{
        En:"Phone Number",
        Fr:"Numéro de téléphone",
        Ar:"رقم الهاتف"
    },
    comment:{
        En:"Comment",
        Fr:"Commentaire",
        Ar:"تعليق"
    },
    send:{
        En:"Send responses",
        Fr:"Envoyer les réponses",
        Ar:"إرسال الردود"
    },
    required:{
        En:"All fields are required",
        Fr:"Toutes les champs sont obligatoire",
        Ar:"جميع الحقول مطلوبة"
    },
    estim:{
        En:"Enter an estimation in DHs",
        Fr:"Entrer une estimation en DHs",
        Ar:"أدخل تقديرًا بالدرهم"
    },
    tanger:{
        En:"Tanger-Tétouan-Al Hoceïma",
        Fr:"Tanger-Tétouan-Al Hoceïma",
        Ar:"طنجة تطوان الحسيمة"
    },
    oriontal:{
        En:"L'Oriental",
        Fr:"L'Oriental",
        Ar:"الشرق"
    },
    fes:{
        En:"Fès-Meknès",
        Fr:"Fès-Meknès",
        Ar:"فاس مكناس"
    },
    rabat:{
        En:"Rabat-Salé-Kénitra",
        Fr:"Rabat-Salé-Kénitra",
        Ar:"الرباط سلا القنيطرة"
    },
    beniMellal:{
        En:"Béni Mellal-Khénifra",
        Fr:"Béni Mellal-Khénifra",
        Ar:"بني ملال خنيفرة"
    },
    casa:{
        En:"Casablanca-Settat",
        Fr:"Casablanca-Settat",
        Ar:"الدار البيضاء سطات"
    },
    marrakech:{
        En:"Marrakech-Safi",
        Fr:"Marrakech-Safi",
        Ar:"مراكش آسفي"
    },
    draa:{
        En:"Drâa-Tafilalet",
        Fr:"Drâa-Tafilalet",
        Ar:"درعة تافيلالت"
    },
    souss:{
        En:"Souss-Massa",
        Fr:"Souss-Massa",
        Ar:" سوس ماسة"
    },
    guelmim:{
        En:"Guelmim-Oued Noun",
        Fr:"Guelmim-Oued Noun",
        Ar:"كلميم واد نون"
    },
    laayoun:{
        En:"Laâyoune-Sakia El Hamra",
        Fr:"Laâyoune-Sakia El Hamra",
        Ar:"العيون الساقية الحمراء"
    },
    dakhla:{
        En:"Dakhla-Oued Ed-Dahab",
        Fr:"Dakhla-Oued Ed-Dahab",
        Ar:" الداخلة وادي الذهب"
    },
    Percent:{
        En:"Percentage of solar energy coverage in %",
        Fr:"Pourcentage de couverture par l'énergie Solaire en %",
        Ar:" نسبة تغطية الطاقة الشمسية (%)"
    },
    note:{
        En:"Note: This is only a quick and automatic estimate of your investment costs and does not in any way commit Solairius to provide you with its services.",
        Fr:"Note: Ceci est uniquement une estimation rapide et automatique de votre couts d'investissement et n'engage en aucun cas Solairius à vous fournir ses services.",
        Ar:"ملاحظة: هذا مجرد تقدير سريع وتلقائي لتكاليف استثمارك ولا يلزم سولاريوس بأي حال من الأحوال بتزويدك بخدماتها."
    }
    
    

   
}