import React, { Component } from "react";
import "./ContactUs.css"
import axios from "axios";
import content from '../../Contents/AboutUsContent';
import content2 from '../../Contents/NavBarContent';

// Local env configuration
require('dotenv').config()
const host_url = process.env.REACT_APP_API_URL



class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.Language = this.props.language;
        this.state={
            err:"",
            name:"",
            email:"",
            phoneNumber:"",
            message:""
        }
        this.changeName = this.changeName.bind(this);
        this.changeEmail = this.changeEmail.bind(this);
        this.changePhoneNumber = this.changePhoneNumber.bind(this);
        this.changeMessage = this.changeMessage.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    changeName(e){
        this.setState({
            name: e.target.value
        })
    }
    changeEmail(e){
        this.setState({
            email: e.target.value
        })
    }
    changePhoneNumber(e){
        this.setState({
            phoneNumber: e.target.value
        })
    }
    changeMessage(e){
        this.setState({
            message: e.target.value
        })
    }

    onSubmit(e){
        e.preventDefault()

       var message={
            name: this.state.name,
            email: this.state.email,
            phoneNumber: this.state.phoneNumber,
            message: this.state.message,
        }
        axios.post(`${host_url}/api/message`, message)
            .then(res =>
                this.setState({
                    err: res.data
                })
                
            )

    }

    render() {
        return (

            <div className="container">
                <h3 className="title2">{content2.aboutUs[this.Language]}</h3>
                <div className="about">
                    <div className="container">
                        <div className="row">
                            <div className="ptext">
                                <h1 className="aboutustitle">{content.aboutustitle[this.Language]}</h1>
                                <p className="aboutusp">{content.aboutuscontent1[this.Language]} </p>
                                <p className="aboutusp p2">{content.aboutuscontent2[this.Language]} </p>
                                <p className="aboutusp p2">{content.aboutuscontent3[this.Language]} </p>
                                <div className="icons ">
                                    <i className="fa fa-facebook fa-lg"></i>
                                    <i className="fa fa-linkedin fa-lg"></i>
                                    <i className="fa fa-twitter fa-lg"></i>
                                    <i className="fa fa-instagram fa-lg"></i>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <h3 className="title aos-init aos-animate">{content2.contactus[this.Language]}</h3><br />
                <div className="row card1">
                    <div className="col-lg-4 col-md-5">
                        <div className="contact-info">
                            <h3>{content.toutDemande[this.Language]}</h3>
                            <div className="contact-info-item">
                                <i className="fa fa-location-arrow"></i>
                                <h4>{content.localisation[this.Language]}</h4>
                                <p>26, Avenue Mers Sultant, appt 3, étage 1, casablanca.</p>
                            </div>
                            <div className="contact-info-item">
                                <i className="fa fa-envelope"></i>
                                <h4>{content.address[this.Language]}</h4>
                                <p>Info@solairius.com</p>
                            </div>
                            <div className="contact-info-item">
                                <i className="fa fa-phone"></i>
                                <h4>{content.appeler[this.Language]}</h4>
                                <p>+212 - 600 777 177</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-7">
                        <div className="contact-form">
                            <form id="form" method="POST" onSubmit={this.onSubmit}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="text" name="name" placeholder={content.name[this.Language]} className="form-control" onChange={this.changeName}/>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <input type="email" name="email" placeholder={content.email[this.Language]} className="form-control" onChange={this.changeEmail}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <input type="text" name="phone" placeholder={content.phone[this.Language]} className="form-control" onChange={this.changePhoneNumber}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <textarea placeholder={content.message[this.Language]} name="message" className="form-control"onChange={this.changeMessage}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <button type="submit" className="btn btn-2">{content.send[this.Language]}</button>
                                    </div>
                                </div>
                            </form>
                            <p>{this.state.err}</p>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ContactUs;