import React, { Component } from "react";
import axios from "axios";
import Popup from 'reactjs-popup';
import { Button, ListGroup, ListGroupItem } from 'reactstrap';
import { Dropdown } from 'react-dropdown-now';
import 'react-dropdown-now/style.css';
import 'reactjs-popup/dist/index.css';
import "./Estimate.css";
import "./Estimate.scss";
import residentiel from "../../Assets/residentiel-logo.png";
import agricole from "../../Assets/agricole.png";
import indistruel from "../../Assets/indistruel.png";
import apartement from "../../Assets/apartement.png";
import maison from "../../Assets/maison.png";
import villa from "../../Assets/villa.png";
import clima from "../../Assets/clima.png";
import ref from "../../Assets/ref.png";
import cong from "../../Assets/cong.png";
import four from "../../Assets/four.png";
import plaque from "../../Assets/plaque.png";
import machine from "../../Assets/machine.png";
import chauffe from "../../Assets/chauffe.png";
import tv from "../../Assets/TV.png";
import ampule from "../../Assets/ampule.png";
import pc from "../../Assets/pc.png";
import jacuzi from "../../Assets/jacuzi.png";
import pompe from "../../Assets/pompe.png";
import pompeSub from "../../Assets/pompeSub.png";
import pompePress from "../../Assets/pompePress.png";
import autrePompe from "../../Assets/autrepompe.png";
import autre from "../../Assets/autre.png";
import brouilleur from "../../Assets/brouilleur.png";
import prodMeta from "../../Assets/prodMeta.png";
import agroAlim from "../../Assets/agroAlim.png";
import logistique from "../../Assets/logistique.png";
import conditionnement from "../../Assets/conditionnement.png";
import frigo from "../../Assets/frigo.png";
import moteur from "../../Assets/moteur.png";
import Compresseur from "../../Assets/compress.png";
import convoyeur from "../../Assets/convoyeur.png";
import bain from "../../Assets/bain.png";
import chariot from "../../Assets/chariot.png";
import content from '../../Contents/estimateContent';

// Local env configuration
require('dotenv').config()
const host_url = process.env.REACT_APP_API_URL

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
};
var puissance = 0;
var puissances = [];
var debit = 0;
var debits = [];
var puissancess = 0;
var nameEquip = "";
var NameEquips = [];
var save = "";

function changePuissance(event) {
    puissance = event.target.value;
};
function changeDebit(event) {
    debit = event.target.value
}
function changeNameEquip(event) {
    nameEquip = event.target.value
}
function savePuissance() {
    puissances.push(puissance)
    debits.push(debit)
    NameEquips.push(nameEquip)
    save = "Saved"
}
function calcPuissance() {
    for (var j = 0; j < puissances.length; j++) {
        puissancess = puissancess + parseFloat(puissances[j], 10)
    }
    return puissancess
}

function inputs(inputs) {
    var inp = [];
    for (var i = 0; i < inputs; i++) {
        inp.push(
            <div key={i} className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="puissance">{i + 1}</label>
                        <input type="number" name="puissance" placeholder="Puissance en KW" className="form-control" onChange={changePuissance} />
                        <input type="number" name="debit" placeholder="Débit" className="form-control" onChange={changeDebit} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="">Merci de Sauvgarder avant d'envoyer les répenses</label>
                        <button type="submit" className="btn" onClick={savePuissance}>Sauvgardé</button>
                    </div>
                    <p>{save}</p>
                </div>
            </div>)
    }
    return inp
}
function inputs1(inputs) {
    var inp = [];
    for (var i = 0; i < inputs; i++) {
        inp.push(
            <div key={i} className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="puissance">{i + 1}</label>
                        <input type="number" name="puissance" placeholder="Puissance en KW" className="form-control" onChange={changePuissance} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="">Merci de Sauvgarder avant d'envoyer les répenses</label>
                        <button type="submit" className="btn" onClick={savePuissance}>Sauvgardé</button>
                    </div>
                    <p>{save}</p>
                </div>
            </div>)
    }
    return inp
}
function inputs2(inputs) {
    var inp = [];
    for (var i = 0; i < inputs; i++) {
        inp.push(
            <div key={i} className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="puissance">{i + 1}</label>
                        <input type="text" name="equipment" placeholder="Equipment" className="form-control" onChange={changeNameEquip} />
                        <input type="number" name="puissance" placeholder="Puissance en KW" className="form-control" onChange={changePuissance} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="">Merci de Sauvgarder avant d'envoyer les répenses</label>
                        <button type="submit" className="btn" onClick={savePuissance}>Sauvgardé</button>
                    </div>
                    <p>{save}</p>
                </div>
            </div>)
    }
    return inp
}
function inputs3(inputs) {
    var inp = [];
    for (var i = 0; i < inputs; i++) {
        inp.push(
            <div key={i} className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="puissance">{i + 1}</label>
                        <input type="text" name="equipment" placeholder="Equipment" className="form-control" onChange={changeNameEquip} />
                        <input type="number" name="puissance" placeholder="Puissance en KW" className="form-control" onChange={changePuissance} />
                        <input type="number" name="debit" placeholder="Débit" className="form-control" onChange={changeDebit} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label htmlFor="">Merci de Sauvgarder avant d'envoyer les répenses</label>
                        <button type="submit" className="btn" onClick={savePuissance}>Sauvgardé</button>
                    </div>
                    <p>{save}</p>
                </div>
            </div>)
    }
    return inp
}

class Estimate extends Component {
    constructor(props) {
        super(props);
        this.Language = this.props.language;

        this.state = {
            estimation: 0,
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            comment: "",
            q: 0,
            title: "",
            region: "",
            elec: "",
            facture: "",
            facture1: "",
            Factures: [],
            generatrice: "",
            residence: "",
            gaz: "",
            autre: "",
            currentQuestion: 0,
            result: false,
            Questions: [],
            bool: false,
            Dropdown: false,
            inputArea: true,
            equipments: true,
            Equipments: [],
            err: "",
            percent: true,
            pourcentage: 0,
            isVilla: false,
            choice1: false,
            choice2: false,
            choice3: false,
            choice4: false,
            choice5: false,
            choice6: false,
            choice7: false,
            choice8: false,
            choice9: false,
            choice10: false,
            choice11: false,
            choice12: false,
            quantité: 0,
            quantity: 1,
            puissance: 0,
            nameEquip: ""
        }
        this.onSubmitAnswer = this.onSubmitAnswer.bind(this)
        this.onSubmitAnswer1 = this.onSubmitAnswer1.bind(this)
        this.onSubmitAnswer2 = this.onSubmitAnswer2.bind(this)
        this.onSubmitAnswer3 = this.onSubmitAnswer3.bind(this)
        this.onSelectAnswer = this.onSelectAnswer.bind(this)
        this.changefirstName = this.changefirstName.bind(this)
        this.changelastName = this.changelastName.bind(this)
        this.changeemail = this.changeemail.bind(this)
        this.changecomment = this.changecomment.bind(this)
        this.changephoneNumber = this.changephoneNumber.bind(this)
        this.changeRegion = this.changeRegion.bind(this)
        this.changeFacture = this.changeFacture.bind(this)
        this.changeFacture1 = this.changeFacture1.bind(this)
        this.changeautre = this.changeautre.bind(this)
        this.changePercent = this.changePercent.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.changeQuantity = this.changeQuantity.bind(this)
        this.changeQuantity1 = this.changeQuantity1.bind(this)
        this.changePuissance = this.changePuissance.bind(this)
        this.changeNameEquip = this.changeNameEquip.bind(this)
    }

    changeNameEquip(event) {
        this.setState({
            nameEquip: event.target.value
        })
    }

    changePuissance(event) {
        this.setState({
            puissance: event.target.value
        })
    }

    changeQuantity(event) {
        this.setState({
            quantité: event.target.value
        })
    }

    changeQuantity1(event) {
        this.setState({
            quantity: event.target.value
        })
    }

    changefirstName(event) {
        this.setState({
            firstName: event.target.value
        })
    }

    changelastName(event) {
        this.setState({
            lastName: event.target.value
        })
    }

    changeemail(event) {
        this.setState({
            email: event.target.value
        })
    }
    changecomment(event) {
        this.setState({
            comment: event.target.value
        })
    }

    changephoneNumber(event) {
        this.setState({
            phoneNumber: event.target.value
        })
    }
    changeRegion(event) {
        this.setState({
            region: event.target.value
        })
    }
    changeFacture(event) {
        this.setState({
            facture: event.target.value
        })
    }
    changeFacture1(event) {
        this.setState({
            facture1: event.target.value
        })
    }

    changeautre(event) {
        this.setState({
            autre: event.target.value
        })
    }
    changePercent(event) {
        this.setState({
            pourcentage: parseFloat(event.target.value)
        })
    }

    onSubmit(event) {

        event.preventDefault()
        var calcul = this.state.estimation;
        var percent = this.state.pourcentage;
        if (this.state.q === 1) {
            if (this.state.elec === "Oui" || this.state.elec === "Yes" || this.state.elec === "نعم") {
                var calcul1 = this.state.Factures[0];
                calcul = calcul * (25 * 17 * 85 * 4) / 100000;
                calcul1 = parseFloat(calcul1) * 40;
                var calTemp = (2 * calcul) / 10;
                var calTemp1 = (2 * calcul1) / 10;
                if (calcul1 < calcul) {
                    if (this.state.generatrice === "Oui" || this.state.generatrice === "Yes" || this.state.generatrice === "نعم") {
                        calcul = calcul - calTemp
                        this.setState({
                            estimation: calcul,
                        })
                    } else {
                        this.setState({
                            estimation: calcul,
                        })
                    }
                } else {
                    if (this.state.generatrice === "Oui" || this.state.generatrice === "Yes" || this.state.generatrice === "نعم") {
                        calcul1 = calcul1 - calTemp1
                        this.setState({
                            estimation: calcul1,
                        })
                    } else {
                        this.setState({
                            estimation: calcul1,
                        })
                    }
                }
            }
            else {
                calcul = calcul * (25 * 17 * 85 * 4) / 100000;
                if (this.state.generatrice === "Oui" || this.state.generatrice === "Yes" || this.state.generatrice === "نعم") {
                    var calTemp11 = (2 * calcul) / 10;
                    calcul = calcul - calTemp11
                    this.setState({
                        estimation: calcul,
                    })
                } else {
                    this.setState({
                        estimation: calcul,
                    })
                }
            }
        } else if (this.state.q === 2) {
            if (this.state.elec === "Oui" || this.state.elec === "Yes" || this.state.elec === "نعم") {
                var calcul11 = this.state.Factures[0];
                var calcul12 = this.state.Factures[1];
                calcul = calcul * (25 * parseFloat(percent) * 79 * 400) / 10000;
                var calcul22 = (parseFloat(calcul11) + parseFloat(calcul12)) * 20;
                if (calcul12 < calcul) {
                    this.setState({
                        estimation: calcul,
                    })
                } else {
                    this.setState({
                        estimation: calcul22,
                    })
                }
            } else {
                calcul = calcul * (25 * 120 * 79 * 400) / 10000;
                this.setState({
                    estimation: calcul,
                })
            }

        } else {
            var calcul31 = this.state.Factures[0];
            calcul31 = parseFloat(calcul31) * 40;
            calcul = calcul * (25 * parseFloat(percent) * 79 * 45) / 100000;
            if (calcul31 < calcul) {
                this.setState({
                    estimation: calcul,
                })
            } else {
                this.setState({
                    estimation: calcul31,
                })
            }
        }
        sleep(100).then(() => {


            const response = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                commentaire: this.state.comment,
                sector: this.state.title,
                region: this.state.region,
                electricite: this.state.elec,
                factures: this.state.Factures,
                gaz: this.state.gaz,
                generatrice: this.state.generatrice,
                type: this.state.residence,
                equipments: this.state.Equipments,
                autres: this.state.autre,
                estimation: this.state.estimation,
                pourcentage: this.state.pourcentage

            }
            axios.post(`${host_url}/api/estimation`, response)
                .then(response => {
                    console.log(response.data)
                })

            if (this.state.firstName === "" || this.state.lastName === "" || this.state.email === "" || this.state.phoneNumber === "") {
                this.setState({
                    err: content.required[this.Language],
                })
            } else if (this.state.pourcentage === 0 && this.state.q !== 1 && (this.state.elec === "Oui" || this.state.elec === "Yes" || this.state.elec === "نعم")) {
                this.setState({
                    err: "La valeur du pourcentage est obligatoire",
                })
            }
            else {
                sleep(100).then(() => {
                    this.setState({
                        result: true,
                    })
                });
            }
        });

    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onSubmitAnswer1() {
        this.setState({
            q: 1,
            title: content.residential[this.Language],
            Questions: [
                {
                    questionText: content.inWhichRegion[this.Language],
                    answerOptions: [
                    ],
                },
                {
                    questionText: content.haveElec[this.Language],
                    answerOptions: [
                        { answerText: content.yes[this.Language], pass: false, pass2: false },
                        { answerText: content.no[this.Language], pass: true, pass2: false },
                    ],
                },
                {
                    questionText: content.elecbill[this.Language],
                    answerOptions: [

                    ],
                },
                {
                    questionText: content.usingGenerator[this.Language],
                    answerOptions: [
                        { answerText: content.yes[this.Language], pass: false, pass2: false },
                        { answerText: content.no[this.Language], pass: false, pass2: false },
                    ],
                },
                {
                    questionText: content.typeResidence[this.Language],
                    answerOptions: [
                        { answerText: content.apartment[this.Language], image: apartement, villa: false },
                        { answerText: content.house[this.Language], image: maison, villa: false },
                        { answerText: content.Villa[this.Language], image: villa, villa: true },
                    ],
                },
                {
                    questionText: content.equipmentInPlace[this.Language],
                    answerOptions: [
                        { answerText: content.clima[this.Language], image: clima },
                        { answerText: content.four[this.Language], image: four },
                        { answerText: content.plaque[this.Language], image: plaque },
                        { answerText: content.chauffe[this.Language], image: chauffe },
                        { answerText: content.pc[this.Language], image: pc },
                        { answerText: content.fridge[this.Language], image: ref },
                        { answerText: content.freezer[this.Language], image: cong },
                        { answerText: content.tv[this.Language], image: tv },
                        { answerText: content.bulb[this.Language], image: ampule },
                        { answerText: content.machine[this.Language], image: machine },
                        { answerText: content.jacuzzi[this.Language], image: jacuzi },
                        { answerText: content.pompe[this.Language], image: pompe },
                    ],
                },
            ]
        })
    }
    onSubmitAnswer2() {
        this.setState({
            q: 2,
            title: content.agricole[this.Language],
            Questions: [
                {
                    questionText: content.inWhichRegion[this.Language],
                    answerOptions: [
                    ],
                },
                {
                    questionText: content.haveElec[this.Language],
                    answerOptions: [
                        { answerText: content.yes[this.Language], pass: true, pass2: false },
                        { answerText: content.no[this.Language], pass: false, pass2: false },
                    ],
                },
                {
                    questionText: content.gaz[this.Language],
                    answerOptions: [
                        { answerText: content.yes[this.Language], pass: false, pass2: true },
                        { answerText: content.no[this.Language], pass: false, pass2: true },

                    ],
                },
                {
                    questionText: content.billWinter[this.Language],
                    questionText1: content.billSummer[this.Language],
                    answerOptions: [

                    ],
                },
                {
                    questionText: content.usingGenerator[this.Language],
                    answerOptions: [
                        { answerText: content.yes[this.Language], pass: false, pass2: false },
                        { answerText: content.no[this.Language], pass: false, pass2: false },
                    ],
                },
                {
                    questionText: content.equipmentInPlace[this.Language],
                    answerOptions: [
                        { answerText: content.pompeSub[this.Language], image: pompeSub },
                        { answerText: content.pompePress[this.Language], image: pompePress },
                        { answerText: content.brouilleur[this.Language], image: brouilleur },
                        { answerText: content.autrePompe[this.Language], image: autrePompe },
                        { answerText: content.autreEquip[this.Language], image: autre },

                    ],
                },
                {
                    questionText: content.Percent[this.Language],
                    answerOptions: [

                    ],
                },
            ]
        })
    }
    onSubmitAnswer3() {
        this.setState({
            q: 3,
            title: content.Industrial[this.Language],
            Questions: [
                {
                    questionText: content.inWhichRegion[this.Language],
                    answerOptions: [
                    ],
                },
                {
                    questionText: content.haveElec[this.Language],
                    answerOptions: [
                        { answerText: content.yes[this.Language], pass: false, pass2: false },
                        { answerText: content.no[this.Language], pass: true, pass2: false },
                    ],
                },
                {
                    questionText: content.elecbill[this.Language],
                    answerOptions: [

                    ],
                },
                {
                    questionText: content.usingGenerator[this.Language],
                    answerOptions: [
                        { answerText: content.yes[this.Language], pass: false, pass2: false },
                        { answerText: content.no[this.Language], pass: false, pass2: false },
                    ],
                },
                {
                    questionText: content.typeUsine[this.Language],
                    answerOptions: [
                        { answerText: content.metal[this.Language], image: prodMeta, villa: false },
                        { answerText: content.agro[this.Language], image: agroAlim, villa: false },
                        { answerText: content.logistique[this.Language], image: logistique, villa: false },
                        { answerText: content.conditionnement[this.Language], image: conditionnement, villa: false },
                        { answerText: content.frigo[this.Language], image: frigo, villa: false },
                        { answerText: content.autre[this.Language], image: autre, villa: false },
                    ],
                },
                {
                    questionText: content.equipmentInPlace[this.Language],
                    answerOptions: [
                        { answerText: content.moteur[this.Language], image: moteur },
                        { answerText: content.compresseur[this.Language], image: Compresseur },
                        { answerText: content.convoyeur[this.Language], image: convoyeur },
                        { answerText: content.brouilleur[this.Language], image: brouilleur },
                        { answerText: content.chauffe[this.Language], image: chauffe },
                        { answerText: 'Bain de levage', image: bain },
                        { answerText: content.clima[this.Language], image: clima },
                        { answerText: content.chariot[this.Language], image: chariot },
                        { answerText: content.autreEquip[this.Language], image: autre },
                    ],
                },
                {
                    questionText: content.Percent[this.Language],
                    answerOptions: [

                    ],
                },
            ]
        })
    }



    onSubmitAnswer(pass, villa, pass2, answer) {
        this.setState({
            isVilla: villa
        })
        if (!pass) {
            if (!pass2) {

                const nextQuestion = this.state.currentQuestion + 1;
                if (nextQuestion < this.state.Questions.length) {
                    this.setState({
                        currentQuestion: nextQuestion,
                    })
                    if (this.state.q === 1) {
                        if (nextQuestion === 0) {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: true,
                                equipments: true,
                            })
                        }
                        else if (nextQuestion === 1) {
                            this.setState({
                                bool: false,
                                Dropdown: true,
                                inputArea: true,
                                equipments: true,
                            })
                        }
                        else if (nextQuestion === 3) {
                            var facTemp = this.state.Factures;
                            facTemp.push(this.state.facture)
                            this.setState({
                                bool: false,
                                Dropdown: true,
                                inputArea: true,
                                equipments: true,
                                Factures: facTemp

                            })
                        }
                        else if (nextQuestion === 2) {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: false,
                                equipments: true,
                                elec: answer,
                            })
                        }
                        else if (nextQuestion === 5) {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: false,
                                equipments: false,
                                residence: answer,
                            })
                        }
                        else if (nextQuestion === 4) {
                            this.setState({
                                bool: true,
                                Dropdown: true,
                                inputArea: true,
                                equipments: true,
                                generatrice: answer,
                            })
                        }
                        else {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: false,
                                equipments: false,
                            })
                        }
                    } else if (this.state.q === 2) {
                        if (nextQuestion === 0) {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: true,
                                equipments: true,
                            })
                        }
                        else if (nextQuestion === 1) {
                            this.setState({
                                bool: false,
                                Dropdown: true,
                                inputArea: true,
                                equipments: true,
                            })
                        }
                        else if (nextQuestion === 2) {
                            this.setState({
                                bool: false,
                                Dropdown: true,
                                inputArea: true,
                                equipments: true,
                                elec: answer,
                            })
                        }
                        else if (nextQuestion === 3) {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: false,
                                equipments: true,
                                gaz: answer,
                            })
                        }
                        else if (nextQuestion === 4) {
                            var facTemp1 = this.state.Factures;
                            facTemp1.push(this.state.facture)
                            facTemp1.push(this.state.facture1)
                            this.setState({
                                bool: false,
                                Dropdown: true,
                                inputArea: true,
                                equipments: true,
                                Factures: facTemp1
                            })
                        }
                        else if (nextQuestion === 5) {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: false,
                                equipments: false,
                                percent: true,
                                generatrice: answer
                            })
                        }
                        else {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: false,
                                equipments: false,
                                percent: false,
                            })
                        }

                    } else {
                        if (nextQuestion === 0) {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: true,
                                equipments: true,
                            })
                        }
                        else if (nextQuestion === 1) {
                            this.setState({
                                bool: false,
                                Dropdown: true,
                                inputArea: true,
                                equipments: true,
                            })
                        }
                        else if (nextQuestion === 3) {
                            var facTemp3 = this.state.Factures;
                            facTemp3.push(this.state.facture)
                            this.setState({
                                bool: false,
                                Dropdown: true,
                                inputArea: true,
                                equipments: true,
                                Factures: facTemp3

                            })
                        }
                        else if (nextQuestion === 2) {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: false,
                                equipments: true,
                                elec: answer,
                            })
                        }
                        else if (nextQuestion === 5) {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: false,
                                equipments: false,
                                residence: answer,
                            })
                        }
                        else if (nextQuestion === 4) {
                            this.setState({
                                bool: true,
                                Dropdown: true,
                                inputArea: true,
                                equipments: true,
                                generatrice: answer,
                            })
                        }
                        else {
                            this.setState({
                                bool: false,
                                Dropdown: false,
                                inputArea: false,
                                equipments: false,
                                percent: false,
                            })
                        }
                    }

                } else {
                    this.setState({
                        result: true,
                    })
                }
            } else {
                const nextQuestion = this.state.currentQuestion + 2;
                if (nextQuestion < this.state.Questions.length) {
                    this.setState({
                        currentQuestion: nextQuestion,
                        gaz: answer
                    })
                } else {
                    this.setState({
                        result: true,
                    })
                }
            }
        } else {
            const nextQuestion = this.state.currentQuestion + 2;
            if (nextQuestion < this.state.Questions.length) {
                if (this.state.q === 1 || this.state.q === 3) {
                    this.setState({
                        currentQuestion: nextQuestion,
                        elec: answer,
                    })
                }
                else if (this.state.q === 2) {
                    this.setState({
                        currentQuestion: nextQuestion,
                        bool: false,
                        Dropdown: false,
                        inputArea: false,
                        equipments: true,
                        elec: answer,
                    })
                }
            } else {
                this.setState({
                    result: true,
                })
            }
        }


    }
    onSelectAnswer(i, equipment, j) {
        const equiTemp = this.state.Equipments;
        var estimTemp = this.state.estimation;
        equiTemp.push(equipment)
        switch (i) {
            case 1:
                this.setState({
                    choice1: !this.state.choice1,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.choice1) {
                        this.setState({
                            estimation: estimTemp + j
                        })
                    } else {
                        this.setState({
                            estimation: estimTemp - j
                        })
                    }

                });
                sleep(100).then(() => {
                    puissance = 0;
                    puissances = [];
                    debit = 0;
                    debits = [];
                    puissancess = 0;
                    nameEquip = "";
                    NameEquips = [];
                    save = "";
                    this.setState({
                        quantité: 0,
                        quantity: 1
                    })
                });
                break;
            case 2:
                this.setState({
                    choice2: !this.state.choice2,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.q === 3) {
                        if (this.state.choice2) {
                            this.setState({
                                estimation: estimTemp + j * 0.3
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j * 0.3
                            })
                        }
                    } else {
                        if (this.state.choice2) {
                            this.setState({
                                estimation: estimTemp + j
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j
                            })
                        }
                    }
                });
                sleep(100).then(() => {
                    puissance = 0;
                    puissances = [];
                    debit = 0;
                    debits = [];
                    puissancess = 0;
                    nameEquip = "";
                    NameEquips = [];
                    save = "";
                    this.setState({
                        quantité: 0,
                        quantity: 1
                    })
                });
                break;
            case 3:
                this.setState({
                    choice3: !this.state.choice3,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.q === 1) {
                        if (this.state.choice3) {
                            this.setState({
                                estimation: estimTemp + j
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j
                            })
                        }
                    } else if (this.state.q === 2) {
                        if (this.state.choice3) {
                            this.setState({
                                estimation: estimTemp + j * 0.1
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j * 0.1
                            })
                        }
                    } else {
                        if (this.state.choice3) {
                            this.setState({
                                estimation: estimTemp + j * 0.5
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j * 0.5
                            })
                        }
                    }
                });
                sleep(100).then(() => {
                    puissance = 0;
                    puissances = [];
                    debit = 0;
                    debits = [];
                    puissancess = 0;
                    nameEquip = "";
                    NameEquips = [];
                    save = "";
                    this.setState({
                        quantité: 0,
                        quantity: 1
                    })
                });
                break;
            case 4:
                this.setState({
                    choice4: !this.state.choice4,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.q === 3) {
                        if (this.state.choice4) {
                            this.setState({
                                estimation: estimTemp + j * 0.1
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j * 0.1
                            })
                        }
                    } else {
                        if (this.state.choice4) {
                            this.setState({
                                estimation: estimTemp + j
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j
                            })
                        }
                    }
                });
                sleep(100).then(() => {
                    puissance = 0;
                    puissances = [];
                    debit = 0;
                    debits = [];
                    puissancess = 0;
                    nameEquip = "";
                    NameEquips = [];
                    save = "";
                    this.setState({
                        quantité: 0,
                        quantity: 1
                    })
                });
                break;
            case 5:
                this.setState({
                    choice5: !this.state.choice5,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.choice5) {
                        this.setState({
                            estimation: estimTemp + j
                        })
                    } else {
                        this.setState({
                            estimation: estimTemp - j
                        })
                    }
                });
                sleep(100).then(() => {
                    puissance = 0;
                    puissances = [];
                    debit = 0;
                    debits = [];
                    puissancess = 0;
                    nameEquip = "";
                    NameEquips = [];
                    save = "";
                    this.setState({
                        quantité: 0,
                        quantity: 1
                    })
                });
                break;
            case 6:
                this.setState({
                    choice6: !this.state.choice6,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.q === 1) {
                        if (this.state.choice6) {
                            this.setState({
                                estimation: estimTemp + j
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j
                            })
                        }
                    } else {
                        if (this.state.choice6) {
                            this.setState({
                                estimation: estimTemp + j * 0.3
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j * 0.3
                            })
                        }
                    }
                });
                sleep(100).then(() => {
                    puissance = 0;
                    puissances = [];
                    debit = 0;
                    debits = [];
                    puissancess = 0;
                    nameEquip = "";
                    NameEquips = [];
                    save = "";
                    this.setState({
                        quantité: 0,
                        quantity: 1
                    })
                });
                break;
            case 7:
                this.setState({
                    choice7: !this.state.choice7,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.q === 1) {
                        if (this.state.choice7) {
                            this.setState({
                                estimation: estimTemp + j
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j
                            })
                        }
                    } else {
                        if (this.state.choice7) {
                            this.setState({
                                estimation: estimTemp + j * 0.2
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j * 0.2
                            })
                        }
                    }

                });
                sleep(100).then(() => {
                    puissance = 0;
                    puissances = [];
                    debit = 0;
                    debits = [];
                    puissancess = 0;
                    nameEquip = "";
                    NameEquips = [];
                    save = "";
                    this.setState({
                        quantité: 0,
                        quantity: 1
                    })
                });
                break;
            case 8:
                this.setState({
                    choice8: !this.state.choice8,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.q === 1) {
                        if (this.state.choice8) {
                            this.setState({
                                estimation: estimTemp + j
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j
                            })
                        }
                    } else {
                        if (this.state.choice8) {
                            this.setState({
                                estimation: estimTemp + j * 0.2
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j * 0.2
                            })
                        }
                    }
                });
                sleep(100).then(() => {
                    puissance = 0;
                    puissances = [];
                    debit = 0;
                    debits = [];
                    puissancess = 0;
                    nameEquip = "";
                    NameEquips = [];
                    save = "";
                    this.setState({
                        quantité: 0,
                        quantity: 1
                    })
                });
                break;
            case 9:
                this.setState({
                    choice9: !this.state.choice9,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.q === 1) {
                        if (this.state.choice9) {
                            this.setState({
                                estimation: estimTemp + j
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j
                            })
                        }
                    } else {
                        if (this.state.choice9) {
                            this.setState({
                                estimation: estimTemp + j * 0.3
                            })
                        } else {
                            this.setState({
                                estimation: estimTemp - j * 0.3
                            })
                        }
                    }

                });
                sleep(100).then(() => {
                    puissance = 0;
                    puissances = [];
                    debit = 0;
                    debits = [];
                    puissancess = 0;
                    nameEquip = "";
                    NameEquips = [];
                    save = "";
                    this.setState({
                        quantité: 0,
                        quantity: 1
                    })
                });
                break;
            case 10:
                this.setState({
                    choice10: !this.state.choice10,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.choice10) {
                        this.setState({
                            estimation: estimTemp + j
                        })
                    } else {
                        this.setState({
                            estimation: estimTemp - j
                        })
                    }
                });
                break;
            case 11:
                this.setState({
                    choice11: !this.state.choice11,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.choice111) {
                        this.setState({
                            estimation: estimTemp + j
                        })
                    } else {
                        this.setState({
                            estimation: estimTemp - j
                        })
                    }
                });
                break;
            case 12:
                this.setState({
                    choice12: !this.state.choice12,
                    Equipments: equiTemp,
                })
                sleep(100).then(() => {
                    if (this.state.choice12) {
                        this.setState({
                            estimation: estimTemp + j
                        })
                    } else {
                        this.setState({
                            estimation: estimTemp - j
                        })
                    }
                });
                break;
            default:
                console.log("Hajjaji Ayyoub")
        }

    }


    render() {
        return (

            <div className="estimate">

                <div className="bubbles">
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>
                    <div className="bubble"></div>

                    {this.state.result ? (
                        <div className="result-section container pt-5">
                            <h3>{content.estimation[this.Language]}</h3>
                            <div className="card-result">
                                <h2>{this.state.estimation} DHs</h2>
                            </div>
                            <br /><h3>{content.etude[this.Language]}</h3><br />
                            <p>{content.note[this.Language]}
                                <br />{content.etude[this.Language]}.</p>
                            <ListGroup className="container card-body ml-5 mr-5 pb-5" >
                                <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                            </ListGroup>
                        </div>

                    ) : this.state.q === 0 ?
                        (
                            <div className="container">
                                <div className="container mt-5 pd-5 pb-5 " >
                                    <div className="question-section">
                                        <div className="question-text  pt-5">
                                            <h3>{content.sector[this.Language]}</h3>
                                        </div>
                                    </div>
                                    <div className="answersCards">
                                        <div className="card" onClick={this.onSubmitAnswer1}>
                                            <img src={residentiel} className="cardImg" alt="" />
                                            <div className="cardbg"></div>
                                            <div className="cardContent">
                                                <h3>{content.residential[this.Language]}</h3>
                                            </div>
                                        </div>
                                        <div className="card" onClick={this.onSubmitAnswer2}>
                                            <img src={agricole} className="cardImg" alt="" />
                                            <div className="cardbg"></div>
                                            <div className="cardContent">
                                                <h3>{content.agricole[this.Language]}</h3>
                                            </div>
                                        </div>
                                        <div className="card" onClick={this.onSubmitAnswer3}>
                                            <img src={indistruel} className="cardImg" alt="" />
                                            <div className="cardbg"></div>
                                            <div className="cardContent">
                                                <h3>{content.Industrial[this.Language]}</h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        ) : this.state.bool ?
                            (
                                <div className="container">
                                    <div className="container mt-5 pd-5 pb-5 " >
                                        <div className="question-section">
                                            <div className="question-text  pt-5">
                                                <h2 className="titleEstimate" >{this.state.title}</h2>
                                                <h3>{this.state.Questions[this.state.currentQuestion].questionText}</h3>
                                            </div>
                                        </div>
                                        <div className="answersCards">
                                            {this.state.Questions[this.state.currentQuestion].answerOptions.map((answerOption) => (
                                                <div className="card" onClick={() => this.onSubmitAnswer(false, answerOption.villa, false, answerOption.answerText)}>
                                                    <img src={answerOption.image} className="cardImg" alt="" />
                                                    <div className="cardbg"></div>
                                                    <div className="cardContent">
                                                        <h3>{answerOption.answerText}</h3>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <ListGroup className="ml-5 mr-5 pb-5" >
                                            <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                        </ListGroup>
                                    </div>
                                </div>
                            ) : this.state.Dropdown ? (
                                <div className="container">
                                    <div className="container mt-5 pd-5 pb-5 " >
                                        <div className="question-section">
                                            <div className="question-text  pt-5">
                                                <h2 className="titleEstimate" >{this.state.title}</h2>
                                                <h3>{this.state.Questions[this.state.currentQuestion].questionText}</h3>
                                            </div>
                                        </div>
                                        <ListGroup className="ml-5 mr-5" >
                                            {this.state.Questions[this.state.currentQuestion].answerOptions.map((answerOption) => (
                                                <ListGroupItem className="answer" onClick={() => this.onSubmitAnswer(answerOption.pass, false, answerOption.pass2, answerOption.answerText)}><h5>{answerOption.answerText}</h5> </ListGroupItem>
                                            ))}
                                        </ListGroup>
                                        <ListGroup className="ml-5 mr-5 pb-5" >
                                            <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                        </ListGroup>
                                    </div>
                                </div>
                            ) : this.state.inputArea ? (
                                <div className="container">
                                    <div className="container mt-5 pd-5 pb-5 " >
                                        <div className="question-section">
                                            <div className="question-text  pt-5">
                                                <h2 className="titleEstimate" >{this.state.title}</h2>
                                                <h3>{this.state.Questions[this.state.currentQuestion].questionText}</h3>
                                            </div>
                                        </div>
                                        <Dropdown
                                            placeholder={content.selectRegion[this.Language]}
                                            className="regions"
                                            options={[
                                                { key: 1, value: content.tanger[this.Language] },
                                                { key: 2, value: content.oriontal[this.Language] },
                                                { key: 3, value: content.fes[this.Language] },
                                                { key: 4, value: content.rabat[this.Language] },
                                                { key: 5, value: content.beniMellal[this.Language] },
                                                { key: 6, value: content.casa[this.Language] },
                                                { key: 7, value: content.marrakech[this.Language] },
                                                { key: 8, value: content.draa[this.Language] },
                                                { key: 9, value: content.souss[this.Language] },
                                                { key: 10, value: content.guelmim[this.Language] },
                                                { key: 11, value: content.laayoun[this.Language] },
                                                { key: 12, value: content.dakhla[this.Language] },
                                            ]}
                                            onChange={(value) => this.setState({ region: value.value })}
                                            onSelect={() => this.onSubmitAnswer(false, false, false)}
                                        />
                                        <ListGroup className="ml-5 mr-5 pb-5" >
                                            <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                        </ListGroup>
                                    </div>
                                </div>
                            ) : this.state.equipments ? this.state.q === 2 ? (
                                <div className="container">
                                    <div className="container mt-5 pd-5 pb-5 " >
                                        <div className="question-section">
                                            <div className="question-text  pt-5">
                                                <h2 className="titleEstimate" >{this.state.title}</h2>
                                                <h3>{this.state.Questions[this.state.currentQuestion].questionText}</h3>
                                            </div>
                                        </div>
                                        <input type="number" placeholder={content.estim[this.Language]} onChange={this.changeFacture} />
                                        <h3>{this.state.Questions[this.state.currentQuestion].questionText1}</h3>
                                        <input type="number" placeholder={content.estim[this.Language]} onChange={this.changeFacture1} />
                                        <div className="row d-flex justify-content-center">
                                            <ListGroup className="ml-5 mr-5 pb-5" >
                                                <Button className="myButton reset d-flex justify-content-center" onClick={() => this.onSubmitAnswer(false, false, false)}>{content.submit[this.Language]}</Button>
                                            </ListGroup>
                                            <ListGroup className="ml-5 mr-5 pb-5" >
                                                <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                            </ListGroup>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="container">
                                    <div className="container mt-5 pd-5 pb-5 " >
                                        <div className="question-section">
                                            <div className="question-text  pt-5">
                                                <h2 className="titleEstimate" >{this.state.title}</h2>
                                                <h3>{this.state.Questions[this.state.currentQuestion].questionText}</h3>
                                            </div>
                                        </div>
                                        <input type="number" placeholder={content.estim[this.Language]} onChange={this.changeFacture} />
                                        <div className="row d-flex justify-content-center">
                                            <ListGroup className="ml-5 mr-5 pb-5" >
                                                <Button className="myButton reset d-flex justify-content-center" onClick={() => this.onSubmitAnswer(false, false, false)}>{content.submit[this.Language]}</Button>
                                            </ListGroup>
                                            <ListGroup className="ml-5 mr-5 pb-5" >
                                                <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                            </ListGroup>
                                        </div>
                                    </div>
                                </div>
                            ) : this.state.percent ?
                                (this.state.q === 1 ? (
                                    <div className="container">
                                        <div className="container mt-5 pd-5 pb-5 " >
                                            <div className="question-section">
                                                <div className="question-text  pt-5">
                                                    <h2 className="titleEstimate" >{this.state.title}</h2>
                                                    <h3>{this.state.Questions[this.state.currentQuestion].questionText}</h3>
                                                </div>
                                            </div>
                                            <div className="answersCards">
                                                <div className="card" onClick={() => this.onSelectAnswer(1, this.state.Questions[this.state.currentQuestion].answerOptions[0].answerText, 6000)}>
                                                    <div className={this.state.choice1 ? "cardbg1" : "cardbg"}></div>
                                                    <i className={this.state.choice1 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                    <img src={this.state.Questions[this.state.currentQuestion].answerOptions[0].image} className="cardImg" alt="" />
                                                    <div className="cardContent">
                                                        <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[0].answerText}</h4>
                                                    </div>
                                                </div>
                                                <div className="card" onClick={() => this.onSelectAnswer(2, this.state.Questions[this.state.currentQuestion].answerOptions[1].answerText, 4000)}>
                                                    <div className={this.state.choice2 ? "cardbg1" : "cardbg"}></div>
                                                    <i className={this.state.choice2 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                    <img src={this.state.Questions[this.state.currentQuestion].answerOptions[1].image} className="cardImg" alt="" />
                                                    <div className="cardContent">
                                                        <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[1].answerText}</h4>
                                                    </div>
                                                </div>
                                                <div className="answersCards">
                                                    <div className="card" onClick={() => this.onSelectAnswer(3, this.state.Questions[this.state.currentQuestion].answerOptions[2].answerText, 2500)}>
                                                        <div className={this.state.choice3 ? "cardbg1" : "cardbg"}></div>
                                                        <i className={this.state.choice3 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                        <img src={this.state.Questions[this.state.currentQuestion].answerOptions[2].image} className="cardImg" alt="" />
                                                        <div className="cardContent">
                                                            <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[2].answerText}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card" onClick={() => this.onSelectAnswer(4, this.state.Questions[this.state.currentQuestion].answerOptions[3].answerText, 5000)}>
                                                    <div className={this.state.choice4 ? "cardbg1" : "cardbg"}></div>
                                                    <i className={this.state.choice4 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                    <img src={this.state.Questions[this.state.currentQuestion].answerOptions[3].image} className="cardImg" alt="" />
                                                    <div className="cardContent">
                                                        <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[3].answerText}</h4>
                                                    </div>
                                                </div>
                                                <Popup
                                                    modal
                                                    contentStyle={{
                                                        background: "rgba(255,255,255,0",
                                                        width: "50%",
                                                        border: "none"
                                                    }}
                                                    trigger={
                                                        <div className="card" onClick={() => this.onSelectAnswer(5, this.state.Questions[this.state.currentQuestion].answerOptions[4].answerText, 100)}>
                                                            <div className={this.state.choice5 ? "cardbg1" : "cardbg"}></div>
                                                            <i className={this.state.choice5 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                            <img src={this.state.Questions[this.state.currentQuestion].answerOptions[4].image} className="cardImg" alt="" />
                                                            <div className="cardContent">
                                                                <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[4].answerText}</h4>
                                                            </div>
                                                        </div>
                                                    }  >{close => (
                                                        <div className="popupDiv">
                                                            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <input type="number" placeholder="Quantité" onChange={this.changeQuantity1} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button onClick={() => {
                                                                this.onSelectAnswer(5, this.state.Questions[this.state.currentQuestion].answerOptions[4].answerText + "/" + this.state.quantity, 100 * parseFloat(this.state.quantity)); sleep(100).then(close)
                                                            }}>Enregistrer</button>
                                                        </div>
                                                    )}
                                                </Popup>
                                                <Popup
                                                    modal
                                                    contentStyle={{
                                                        background: "rgba(255,255,255,0",
                                                        width: "50%",
                                                        border: "none"
                                                    }}
                                                    trigger={
                                                        <div className="card" onClick={() => this.onSelectAnswer(6)}>
                                                            <div className={this.state.choice6 ? "cardbg1" : "cardbg"}></div>
                                                            <i className={this.state.choice6 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                            <img src={this.state.Questions[this.state.currentQuestion].answerOptions[5].image} className="cardImg" alt="" />
                                                            <div className="cardContent">
                                                                <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[5].answerText}</h4>
                                                            </div>
                                                        </div>
                                                    }  >{close => (
                                                        <div className="popupDiv">
                                                            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                            <button onClick={() => { this.onSelectAnswer(6, this.state.Questions[this.state.currentQuestion].answerOptions[5].answerText + "/Petit", 480); sleep(100).then(close) }}>{content.petit[this.Language]}</button>
                                                            <button onClick={() => { this.onSelectAnswer(6, this.state.Questions[this.state.currentQuestion].answerOptions[5].answerText + "/Moyenne", 600); sleep(100).then(close) }}>{content.moyenne[this.Language]}</button>
                                                            <button onClick={() => { this.onSelectAnswer(6, this.state.Questions[this.state.currentQuestion].answerOptions[5].answerText + "/Grand", 720); sleep(100).then(close) }}>{content.grand[this.Language]}</button>
                                                        </div>
                                                    )}
                                                </Popup>
                                                <Popup
                                                    modal
                                                    contentStyle={{
                                                        background: "rgba(255,255,255,0",
                                                        width: "50%",
                                                        border: "none"
                                                    }}
                                                    trigger={
                                                        <div className="card" onClick={() => this.onSelectAnswer(7)}>
                                                            <div className={this.state.choice7 ? "cardbg1" : "cardbg"}></div>
                                                            <i className={this.state.choice7 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                            <img src={this.state.Questions[this.state.currentQuestion].answerOptions[6].image} className="cardImg" alt="" />
                                                            <div className="cardContent">
                                                                <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[6].answerText}</h4>
                                                            </div>
                                                        </div>
                                                    }  >{close => (
                                                        <div className="popupDiv">
                                                            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                            <button onClick={() => { this.onSelectAnswer(7, this.state.Questions[this.state.currentQuestion].answerOptions[6].answerText + "/Petit", 500); sleep(100).then(close) }}>{content.petit[this.Language]}</button>
                                                            <button onClick={() => { this.onSelectAnswer(7, this.state.Questions[this.state.currentQuestion].answerOptions[6].answerText + "/Moyenne", 700); sleep(100).then(close) }}>{content.moyenne[this.Language]}</button>
                                                            <button onClick={() => { this.onSelectAnswer(7, this.state.Questions[this.state.currentQuestion].answerOptions[6].answerText + "/Grand", 900); sleep(100).then(close) }}>{content.grand[this.Language]}</button>

                                                        </div>
                                                    )}
                                                </Popup>
                                                <Popup
                                                    modal
                                                    contentStyle={{
                                                        background: "rgba(255,255,255,0",
                                                        width: "50%",
                                                        border: "none"
                                                    }}
                                                    trigger={
                                                        <div className="card" onClick={() => this.onSelectAnswer(8)}>
                                                            <div className={this.state.choice8 ? "cardbg1" : "cardbg"}></div>
                                                            <i className={this.state.choice8 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                            <img src={this.state.Questions[this.state.currentQuestion].answerOptions[7].image} className="cardImg" alt="" />
                                                            <div className="cardContent">
                                                                <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[7].answerText}</h4>
                                                            </div>
                                                        </div>
                                                    }  >{close => (
                                                        <div className="popupDiv">
                                                            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <input type="number" placeholder="Quantité" onChange={this.changeQuantity1} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button onClick={() => { this.onSelectAnswer(8, this.state.Questions[this.state.currentQuestion].answerOptions[7].answerText + "/Cathodique" + "/" + this.state.quantity, 450 * parseFloat(this.state.quantity)); sleep(100).then(close) }}>{content.cathodic[this.Language]}</button>
                                                            <button onClick={() => { this.onSelectAnswer(8, this.state.Questions[this.state.currentQuestion].answerOptions[7].answerText + "/Moin de 32 pouce" + "/" + this.state.quantity, 200 * parseFloat(this.state.quantity)); sleep(100).then(close) }}>{content.less32[this.Language]}</button>
                                                            <button onClick={() => { this.onSelectAnswer(8, this.state.Questions[this.state.currentQuestion].answerOptions[7].answerText + "/32 à 50 pouce" + "/" + this.state.quantity, 400 * parseFloat(this.state.quantity)); sleep(100).then(close) }}>{content.more32[this.Language]}</button>
                                                        </div>
                                                    )}
                                                </Popup>
                                                <Popup
                                                    modal
                                                    contentStyle={{
                                                        background: "rgba(255,255,255,0",
                                                        width: "50%",
                                                        border: "none"
                                                    }}
                                                    trigger={
                                                        <div className="card" onClick={() => this.onSelectAnswer(9)}>
                                                            <div className={this.state.choice9 ? "cardbg1" : "cardbg"}></div>
                                                            <i className={this.state.choice9 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                            <img src={this.state.Questions[this.state.currentQuestion].answerOptions[8].image} className="cardImg" alt="" />
                                                            <div className="cardContent">
                                                                <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[8].answerText}</h4>
                                                            </div>
                                                        </div>
                                                    }  >{close => (
                                                        <div className="popupDiv">
                                                            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="form-group">
                                                                        <input type="number" placeholder="Quantité" onChange={this.changeQuantity1} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button onClick={() => { this.onSelectAnswer(9, this.state.Questions[this.state.currentQuestion].answerOptions[8].answerText + "/60 W" + "/" + this.state.quantity, 240 * parseFloat(this.state.quantity)); sleep(100).then(close) }}>60 W</button>
                                                            <button onClick={() => { this.onSelectAnswer(9, this.state.Questions[this.state.currentQuestion].answerOptions[8].answerText + "/10 W" + "/" + this.state.quantity, 40 * parseFloat(this.state.quantity)); sleep(100).then(close) }}>10 W</button>
                                                            <button onClick={() => { this.onSelectAnswer(9, this.state.Questions[this.state.currentQuestion].answerOptions[8].answerText + "/6 W" + "/" + this.state.quantity, 24 * parseFloat(this.state.quantity)); sleep(100).then(close) }}>6 W</button>
                                                        </div>
                                                    )}
                                                </Popup>
                                                <Popup
                                                    modal
                                                    contentStyle={{
                                                        background: "rgba(255,255,255,0",
                                                        width: "50%",
                                                        border: "none"
                                                    }}
                                                    trigger={
                                                        <div className="card" onClick={() => this.onSelectAnswer(10)}>
                                                            <div className={this.state.choice10 ? "cardbg1" : "cardbg"}></div>
                                                            <i className={this.state.choice10 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                            <img src={this.state.Questions[this.state.currentQuestion].answerOptions[9].image} className="cardImg" alt="" />
                                                            <div className="cardContent">
                                                                <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[9].answerText}</h4>
                                                            </div>
                                                        </div>
                                                    }  >{close => (
                                                        <div className="popupDiv">
                                                            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                            <button onClick={() => { this.onSelectAnswer(10, this.state.Questions[this.state.currentQuestion].answerOptions[9].answerText + "/Petit", 140); sleep(100).then(close) }}>{content.petit[this.Language]}</button>
                                                            <button onClick={() => { this.onSelectAnswer(10, this.state.Questions[this.state.currentQuestion].answerOptions[9].answerText + "/Moyenne", 280); sleep(100).then(close) }}>{content.moyenne[this.Language]}</button>
                                                            <button onClick={() => { this.onSelectAnswer(10, this.state.Questions[this.state.currentQuestion].answerOptions[9].answerText + "/Grand", 500); sleep(100).then(close) }}>{content.grand[this.Language]}</button>
                                                        </div>
                                                    )}
                                                </Popup>
                                            </div>
                                            {this.state.isVilla ? (
                                                <div className="answersCards">
                                                    <div className="card" onClick={() => this.onSelectAnswer(11, this.state.Questions[this.state.currentQuestion].answerOptions[10].answerText, 4500)}>
                                                        <div className={this.state.choice11 ? "cardbg1" : "cardbg"}></div>
                                                        <i className={this.state.choice11 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                        <img src={this.state.Questions[this.state.currentQuestion].answerOptions[10].image} className="cardImg" alt="" />
                                                        <div className="cardContent">
                                                            <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[10].answerText}</h4>
                                                        </div>
                                                    </div>
                                                    <div className="card" onClick={() => this.onSelectAnswer(12, this.state.Questions[this.state.currentQuestion].answerOptions[11].answerText, 3000)}>
                                                        <div className={this.state.choice12 ? "cardbg1" : "cardbg"}></div>
                                                        <i className={this.state.choice12 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                        <img src={this.state.Questions[this.state.currentQuestion].answerOptions[11].image} className="cardImg" alt="" />
                                                        <div className="cardContent">
                                                            <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[11].answerText}</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : <div></div>
                                            }
                                            < input type="text" placeholder={content.autreEquip[this.Language]} name="Autre" className="form-control" value={this.state.autre} onChange={this.changeautre} />
                                            {(this.state.q === 3 || this.state.q === 2) && (this.state.elec === "Oui" || this.state.elec === "Yes" || this.state.elec === "نعم") ? (
                                                <div className="row d-flex justify-content-center">
                                                    <ListGroup className="ml-5 mr-5 pb-5" >
                                                        <Button className="myButton reset d-flex justify-content-center" onClick={() => this.onSubmitAnswer(false, false, false)}>{content.submit[this.Language]}</Button>
                                                    </ListGroup>
                                                    <ListGroup className="ml-5 mr-5 pb-5" >
                                                        <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                                    </ListGroup>
                                                </div>
                                            ) : (
                                                <div className="row d-flex justify-content-center">
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "80%",
                                                            height: "70%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <ListGroup className="ml-5 mr-5 pb-5" >
                                                                <Button className="myButton reset d-flex justify-content-center">{content.submit[this.Language]}</Button>
                                                            </ListGroup>
                                                        }>{close => (
                                                            <div className="popupDiv">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <form method="POST" onSubmit={this.onSubmit}>
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <div className="form-group">
                                                                                    <label>{content.firstName[this.Language]}</label><br />
                                                                                    <input type="text" name="Name" className="form-control" required="" value={this.state.firstName} onChange={this.changefirstName} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="form-group">
                                                                                    <label>{content.lastName[this.Language]}</label><br />
                                                                                    <input type="text" name="lastName" className="form-control" required="" value={this.state.lastName} onChange={this.changelastName} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-lg-6">
                                                                                <div className="form-group">
                                                                                    <label>{content.email[this.Language]}</label>
                                                                                    <input type="email" name="email" className="form-control" required="" value={this.state.email} onChange={this.changeemail} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-lg-6">
                                                                                <div className="form-group">
                                                                                    <label>{content.phoneNumber[this.Language]}</label><br />
                                                                                    <input type="text" name="phoneNumber" className="form-control" required="" value={this.state.phoneNumber} onChange={this.changephoneNumber} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <div className="form-group">
                                                                                    <label>{content.comment[this.Language]}</label>
                                                                                    <textarea type="text" name="comment" className="form-control" value={this.state.comment} onChange={this.changecomment} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-lg-12">
                                                                                <div className="form-group">
                                                                                    <button type="submit" className="btn">{content.send[this.Language]}</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p>{this.state.err}</p>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <ListGroup className="ml-5 mr-5 pb-5" >
                                                        <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                                    </ListGroup>
                                                </div>
                                            )
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    this.state.q === 2 ? (
                                        <div className="container">
                                            <div className="container mt-5 pd-5 pb-5 " >
                                                <div className="question-section">
                                                    <div className="question-text  pt-5">
                                                        <h2 className="titleEstimate" >{this.state.title}</h2>
                                                        <h3>{this.state.Questions[this.state.currentQuestion].questionText}</h3>
                                                    </div>
                                                </div>
                                                <div className="answersCards">
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(1, this.state.Questions[this.state.currentQuestion].answerOptions[0].answerText, 6000)}>
                                                                <div className={this.state.choice1 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice1 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[0].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[0].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(1, this.state.Questions[this.state.currentQuestion].answerOptions[0].answerText + "/" + puissances + "/" + debits, calcPuissance()); sleep(100).then(close) }}>Envoyer les Réponses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(2, this.state.Questions[this.state.currentQuestion].answerOptions[1].answerText, 4000)}>
                                                                <div className={this.state.choice2 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice2 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[1].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[1].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(2, this.state.Questions[this.state.currentQuestion].answerOptions[1].answerText + "/" + puissances + "/" + debits, calcPuissance()); sleep(100).then(close) }}>Envoyer les Réponses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(3, this.state.Questions[this.state.currentQuestion].answerOptions[2].answerText, 2500)}>
                                                                <div className={this.state.choice3 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice3 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[2].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[2].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs1(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(3, this.state.Questions[this.state.currentQuestion].answerOptions[2].answerText + "/" + puissances, calcPuissance()); sleep(100).then(close) }}>Envoyer les Répenses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(4, this.state.Questions[this.state.currentQuestion].answerOptions[3].answerText, 5000)}>
                                                                <div className={this.state.choice4 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice4 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[3].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[3].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs3(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(4, this.state.Questions[this.state.currentQuestion].answerOptions[3].answerText + "/" + NameEquips + "/" + puissances + "/" + debits, calcPuissance()); sleep(100).then(close) }}>Envoyer les Répenses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(5, this.state.Questions[this.state.currentQuestion].answerOptions[4].answerText, 100)}>
                                                                <div className={this.state.choice5 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice5 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[4].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[4].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs2(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(5, this.state.Questions[this.state.currentQuestion].answerOptions[4].answerText + "/" + NameEquips + "/" + puissances, calcPuissance()); sleep(100).then(close) }}>Envoyer les Répenses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                </div>
                                                {(this.state.q === 3 || this.state.q === 2) && (this.state.elec === "Oui" || this.state.elec === "Yes" || this.state.elec === "نعم")? (
                                                    <div className="row d-flex justify-content-center">
                                                        <ListGroup className="ml-5 mr-5 pb-5" >
                                                            <Button className="myButton reset d-flex justify-content-center" onClick={() => this.onSubmitAnswer(false, false, false)}>{content.submit[this.Language]}</Button>
                                                        </ListGroup>
                                                        <ListGroup className="ml-5 mr-5 pb-5" >
                                                            <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                                        </ListGroup>
                                                    </div>
                                                ) : (
                                                    <div className="row d-flex justify-content-center">
                                                        <Popup
                                                            modal
                                                            contentStyle={{
                                                                background: "rgba(255,255,255,0",
                                                                width: "80%",
                                                                height: "70%",
                                                                border: "none"
                                                            }}
                                                            trigger={
                                                                <ListGroup className="ml-5 mr-5 pb-5" >
                                                                    <Button className="myButton reset d-flex justify-content-center">{content.submit[this.Language]}</Button>
                                                                </ListGroup>
                                                            }>{close => (
                                                                <div className="popupDiv1">
                                                                    <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                    <div className="info__card">
                                                                        <form method="POST" onSubmit={this.onSubmit}>
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group">
                                                                                        <label>{content.firstName[this.Language]}</label><br />
                                                                                        <input type="text" name="Name" className="form-control" required="" value={this.state.firstName} onChange={this.changefirstName} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group">
                                                                                        <label>{content.lastName[this.Language]}</label><br />
                                                                                        <input type="text" name="lastName" className="form-control" required="" value={this.state.lastName} onChange={this.changelastName} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group">
                                                                                        <label>{content.email[this.Language]}</label>
                                                                                        <input type="email" name="email" className="form-control" required="" value={this.state.email} onChange={this.changeemail} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group">
                                                                                        <label>{content.phoneNumber[this.Language]}</label><br />
                                                                                        <input type="text" name="phoneNumber" className="form-control" required="" value={this.state.phoneNumber} onChange={this.changephoneNumber} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-12">
                                                                                    <div className="form-group">
                                                                                        <label>{content.comment[this.Language]}</label>
                                                                                        <textarea type="text" name="comment" className="form-control" value={this.state.comment} onChange={this.changecomment} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-12">
                                                                                    <div className="form-group">
                                                                                        <button type="submit" className="btn">{content.send[this.Language]}</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <p>{this.state.err}</p>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Popup>
                                                        <ListGroup className="ml-5 mr-5 pb-5" >
                                                            <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                                        </ListGroup>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="container">
                                            <div className="container mt-5 pd-5 pb-5 " >
                                                <div className="question-section">
                                                    <div className="question-text  pt-5">
                                                        <h2 className="titleEstimate" >{this.state.title}</h2>
                                                        <h3>{this.state.Questions[this.state.currentQuestion].questionText}</h3>
                                                    </div>
                                                </div>
                                                <div className="answersCards">
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(1, this.state.Questions[this.state.currentQuestion].answerOptions[0].answerText, 6000)}>
                                                                <div className={this.state.choice1 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice1 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[0].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[0].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs1(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(1, this.state.Questions[this.state.currentQuestion].answerOptions[0].answerText + "/" + puissances, calcPuissance()); sleep(100).then(close) }}>Envoyer les Réponses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(2, this.state.Questions[this.state.currentQuestion].answerOptions[1].answerText, 4000)}>
                                                                <div className={this.state.choice2 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice2 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[1].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[1].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs1(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(2, this.state.Questions[this.state.currentQuestion].answerOptions[1].answerText + "/" + puissances, calcPuissance()); sleep(100).then(close) }}>Envoyer les Réponses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(3, this.state.Questions[this.state.currentQuestion].answerOptions[2].answerText, 2500)}>
                                                                <div className={this.state.choice3 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice3 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[2].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[2].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs1(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(3, this.state.Questions[this.state.currentQuestion].answerOptions[2].answerText + "/" + puissances, calcPuissance()); sleep(100).then(close) }}>Envoyer les Répenses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(4, this.state.Questions[this.state.currentQuestion].answerOptions[3].answerText, 5000)}>
                                                                <div className={this.state.choice4 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice4 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[3].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[3].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs1(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(4, this.state.Questions[this.state.currentQuestion].answerOptions[3].answerText + "/" + puissances, calcPuissance()); sleep(100).then(close) }}>Envoyer les Répenses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(5, this.state.Questions[this.state.currentQuestion].answerOptions[4].answerText, 100)}>
                                                                <div className={this.state.choice5 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice5 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[4].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[4].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs1(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(5, this.state.Questions[this.state.currentQuestion].answerOptions[4].answerText + "/" + puissances, calcPuissance()); sleep(100).then(close) }}>Envoyer les Répenses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(6, this.state.Questions[this.state.currentQuestion].answerOptions[5].answerText)}>
                                                                <div className={this.state.choice6 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice6 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[5].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[5].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs1(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(6, this.state.Questions[this.state.currentQuestion].answerOptions[5].answerText + "/" + puissances, calcPuissance()); sleep(100).then(close) }}>Envoyer les Répenses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(7, this.state.Questions[this.state.currentQuestion].answerOptions[6].answerText)}>
                                                                <div className={this.state.choice7 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice7 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[6].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[6].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs1(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(7, this.state.Questions[this.state.currentQuestion].answerOptions[6].answerText + "/" + puissances, calcPuissance()); sleep(100).then(close) }}>Envoyer les Répenses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(8, this.state.Questions[this.state.currentQuestion].answerOptions[7].answerText)}>
                                                                <div className={this.state.choice8 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice8 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[7].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[7].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs1(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(8, this.state.Questions[this.state.currentQuestion].answerOptions[7].answerText + "/" + puissances, calcPuissance()); sleep(100).then(close) }}>Envoyer les Répenses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                    <Popup
                                                        modal
                                                        contentStyle={{
                                                            background: "rgba(255,255,255,0",
                                                            width: "50%",
                                                            border: "none"
                                                        }}
                                                        trigger={
                                                            <div className="card" onClick={() => this.onSelectAnswer(9, this.state.Questions[this.state.currentQuestion].answerOptions[8].answerText)}>
                                                                <div className={this.state.choice9 ? "cardbg1" : "cardbg"}></div>
                                                                <i className={this.state.choice9 ? "fa fa-check-circle-o" : "fa fa-circle-o"} aria-hidden="true"></i>
                                                                <img src={this.state.Questions[this.state.currentQuestion].answerOptions[8].image} className="cardImg" alt="" />
                                                                <div className="cardContent">
                                                                    <h4>{this.state.Questions[this.state.currentQuestion].answerOptions[8].answerText}</h4>
                                                                </div>
                                                            </div>
                                                        }  >{close => (
                                                            <div className="popupDiv1">
                                                                <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                <div className="info__card">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <input type="number" placeholder="Quantité" onChange={this.changeQuantity} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {inputs2(this.state.quantité)}
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn" onClick={() => { this.onSelectAnswer(9, this.state.Questions[this.state.currentQuestion].answerOptions[8].answerText + "/" + NameEquips + "/" + puissances, calcPuissance()); sleep(100).then(close) }}>Envoyer les Répenses</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Popup>
                                                </div>
                                                {(this.state.q === 3 || this.state.q === 2) && (this.state.elec === "Oui" || this.state.elec === "Yes" || this.state.elec === "نعم") ? (
                                                    <div className="row d-flex justify-content-center">
                                                        <ListGroup className="ml-5 mr-5 pb-5" >
                                                            <Button className="myButton reset d-flex justify-content-center" onClick={() => this.onSubmitAnswer(false, false, false)}>{content.submit[this.Language]}</Button>
                                                        </ListGroup>
                                                        <ListGroup className="ml-5 mr-5 pb-5" >
                                                            <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                                        </ListGroup>
                                                    </div>
                                                ) : (
                                                    <div className="row d-flex justify-content-center">
                                                        <Popup
                                                            modal
                                                            contentStyle={{
                                                                background: "rgba(255,255,255,0",
                                                                width: "80%",
                                                                height: "70%",
                                                                border: "none"
                                                            }}
                                                            trigger={
                                                                <ListGroup className="ml-5 mr-5 pb-5" >
                                                                    <Button className="myButton reset d-flex justify-content-center">{content.submit[this.Language]}</Button>
                                                                </ListGroup>
                                                            }>{close => (
                                                                <div className="popupDiv">
                                                                    <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                                    <div className="info__card">
                                                                        <form method="POST" onSubmit={this.onSubmit}>
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group">
                                                                                        <label>{content.firstName[this.Language]}</label><br />
                                                                                        <input type="text" name="Name" className="form-control" required="" value={this.state.firstName} onChange={this.changefirstName} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group">
                                                                                        <label>{content.lastName[this.Language]}</label><br />
                                                                                        <input type="text" name="lastName" className="form-control" required="" value={this.state.lastName} onChange={this.changelastName} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group">
                                                                                        <label>{content.email[this.Language]}</label>
                                                                                        <input type="email" name="email" className="form-control" required="" value={this.state.email} onChange={this.changeemail} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="form-group">
                                                                                        <label>{content.phoneNumber[this.Language]}</label><br />
                                                                                        <input type="text" name="phoneNumber" className="form-control" required="" value={this.state.phoneNumber} onChange={this.changephoneNumber} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-12">
                                                                                    <div className="form-group">
                                                                                        <label>{content.comment[this.Language]}</label>
                                                                                        <textarea type="text" name="comment" className="form-control" value={this.state.comment} onChange={this.changecomment} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-lg-12">
                                                                                    <div className="form-group">
                                                                                        <button type="submit" className="btn">{content.send[this.Language]}</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <p>{this.state.err}</p>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Popup>
                                                        <ListGroup className="ml-5 mr-5 pb-5" >
                                                            <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                                        </ListGroup>
                                                    </div>
                                                )
                                                }
                                            </div>
                                        </div>
                                    )
                                )
                                ) : (
                                    <div className="container">
                                        <div className="container mt-5 pd-5 pb-5 " >
                                            <div className="question-section">
                                                <div className="question-text  pt-5">
                                                    <h2 className="titleEstimate" >{this.state.title}</h2>
                                                    <h3>{this.state.Questions[this.state.currentQuestion].questionText}</h3>
                                                </div>
                                            </div>
                                            <input type="number" placeholder={content.Percent[this.Language]} onChange={this.changePercent} required />
                                            <div className="row d-flex justify-content-center">
                                                <Popup
                                                    modal
                                                    contentStyle={{
                                                        background: "rgba(255,255,255,0",
                                                        width: "80%",
                                                        height: "70%",
                                                        border: "none"
                                                    }}
                                                    trigger={
                                                        <ListGroup className="ml-5 mr-5 pb-5" >
                                                            <Button className="myButton reset d-flex justify-content-center">{content.submit[this.Language]}</Button>
                                                        </ListGroup>
                                                    }>{close => (
                                                        <div className="popupDiv">
                                                            <i onClick={close} className="fa fa-times" aria-hidden="true"></i>
                                                            <div className="info__card">
                                                                <form method="POST" onSubmit={this.onSubmit}>
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group">
                                                                                <label>{content.firstName[this.Language]}</label><br />
                                                                                <input type="text" name="Name" className="form-control" required="" value={this.state.firstName} onChange={this.changefirstName} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group">
                                                                                <label>{content.lastName[this.Language]}</label><br />
                                                                                <input type="text" name="lastName" className="form-control" required="" value={this.state.lastName} onChange={this.changelastName} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group">
                                                                                <label>{content.email[this.Language]}</label>
                                                                                <input type="email" name="email" className="form-control" required="" value={this.state.email} onChange={this.changeemail} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <div className="form-group">
                                                                                <label>{content.phoneNumber[this.Language]}</label><br />
                                                                                <input type="text" name="phoneNumber" className="form-control" required="" value={this.state.phoneNumber} onChange={this.changephoneNumber} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <label>{content.comment[this.Language]}</label>
                                                                                <textarea type="text" name="comment" className="form-control" value={this.state.comment} onChange={this.changecomment} />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="form-group">
                                                                                <button type="submit" className="btn">{content.send[this.Language]}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <p>{this.state.err}</p>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Popup>
                                                <ListGroup className="ml-5 mr-5 pb-5" >
                                                    <Button className="myButton reset d-flex justify-content-center" href="/estimate">{content.reset[this.Language]}</Button>
                                                </ListGroup>
                                            </div>
                                        </div>
                                    </div>
                                )
                    }
                </div>

            </div>

        );
    }
}

export default Estimate;