import "./Banner.css";
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Solar from "../../Assets/Solar.png";
import head1 from "../../Assets/head1.png";
import content from '../../Contents/HomeContent'




class Banner extends Component {
    constructor(props) {
        super(props);
        this.Language = this.props.language
    }
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <div>
                <div className="banner">
                    <div className="container ">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-6  ">
                                <div className="row ">
                                    <div className="ml-4 mr-4 ">
                                        <img className="floating img-fluid" src={Solar} alt="Logo title" loading="lazy"/>
                                        <h1 className="txt2">{content.headertitle[this.Language]}</h1>
                                        <p className="mb-3">{content.headercontent1[this.Language]}</p>
                                        <p className="txt">{content.headercontent[this.Language]}</p>
                                        <div className="buttons d-flex justify-content-around justify-content-md-start mb-3  ">
                                            <ul>
                                                <Link className="myButton " to="/estimate">{content.estimateCosts[this.Language]}  </Link>
                                                <Link className="myButton ml-4" to="/faq">{content.haveQuestion[this.Language]}</Link>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col- col-sm col-md- d-flex justify-content-end ">
                                <img className="img-fluid" src={head1} alt="sideimages" loading="lazy"/>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        );
    }
}

export default Banner;
