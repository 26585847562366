import React, { useState } from "react";
import "./login.css";
import loginLogo from "../../Assets/logo.png";
import axios from "axios";
import Dashboard from "../Dashboard/Dashboard";

// Local env configuration
require('dotenv').config()
const host_url = process.env.REACT_APP_API_URL


function Login() {

  const [login, setLogin] = useState();
  const [password, setPassword] = useState();

  const onSubmit = e => {
    e.preventDefault()


    const logedin = {
      login,
      password
    }
    axios.post(`${host_url}/api/admin/connect`, logedin)
      .then(response => {
        localStorage.setItem("accessToken", response.data.accessToken);
        window.location.reload();
      })
  }
  const token =localStorage.getItem("accessToken");
  if(token){
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    return <Dashboard />
  }
  return (
    <div className="loginPage">
      <div className="boxlogin">
        <div className="logologin">
          <img src={loginLogo} alt="Logo Sayertti" />
        </div>
        <form className="loginForm" method="post" onSubmit={onSubmit}>
          <div className="inputbox">
            <input type="text" name="login" placeholder="E-mail" required="" value={login} onChange={e => setLogin(e.target.value)} />
          </div>
          <div className="inputbox">
            <input type="password" name="password" placeholder="Password" required="" value={password} onChange={e => setPassword(e.target.value)} />
          </div>
          <button className="submitLogin" name="login_user">
            Sign In
              </button>
        </form>
      </div>
    </div>
  );
}


export default Login;