module.exports = {
    faqtitle:{
        En:"Frequently asked quetions",
        Fr:"Questions fréquemment posées",
        Ar:"الأسئلة الشائعة"
    },
    faqst:{
        En:"Still have a question ?",
        Fr:"Vous avez encore une question ?",
        Ar:"لا يزال لديك سؤال؟"
    },
    name:{
        En:"Full Name",
        Fr:"Nom Complet",
        Ar:"الاسم بالكامل"
    },
    email:{
        En:"Email",
        Fr:"E-mail",
        Ar:"البريد الإلكتروني"
    },
    phone:{
        En:"Phone Number",
        Fr:"Numéro de Téléphone",
        Ar:"رقم الهاتف"
    },
    question:{
        En:"Your Question",
        Fr:"Votre Question",
        Ar:"سؤالك"
    },
    send:{
        En:"Send",
        Fr:"Envoyer",
        Ar:"إرسال"
    },
  
}