import React from "react";
import './Slidshow.css';


const Slidshow = () => {
return (
    
    <div className="card row">
        <ul className="slideshow">
            <li><span>Image 02</span></li>
            <li><span>Image 03</span></li>
            <li><span>Image 04</span></li>
            <li><span>Image 05</span></li>
            <li><span>Image 06</span></li>
        </ul>
     </div>
    
);
};

export default Slidshow;